
import { defineComponent, provide, onMounted, computed, ref, watch } from "vue";
import { Route } from "vue-router";

import { useRoute, useRouter } from "@/router";

import { assessmentItemIdKey } from "@/injectionKeys";

export default defineComponent({
  name: "fullscreenTestPart",

  props: {
    identifier: { type: String, required: true },
    title: String,
    assessmentItemIdentifier: { type: String, required: true }
  },

  setup(props) {
    const root = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();

    provide(assessmentItemIdKey, props.assessmentItemIdentifier);

    const showComponent = computed(
      () => route.query.partId === props.identifier
    );

    function onNavigation(to: Route["query"]) {
      if (to.partId === props.identifier) {
        const query = { ...to };

        const display = root.value?.querySelector(
          'div[data-tag="crmx"][data-name="nonlinear"][data-value="page"]'
        )
          ? "page"
          : [];

        let push = false;
        if (query.display !== display) {
          query.display = display;
          push = true;
        }

        if (push) {
          router.replace({ query });
        }
      }
    }

    watch(
      () => route.query,
      (to, from) => {
        if (from && to.partId === from.partId) return;
        onNavigation(to);
      }
    );

    onMounted(() => {
      onNavigation(route.query);
    });

    return {
      root,
      showComponent
    };
  }
});
