
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { Route } from "vue-router";

import { useRoute, useRouter } from "@/router";

export default defineComponent({
  name: "testPart",

  props: {
    identifier: String
  },

  setup(props) {
    const root = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();

    const sectionIds: string[] = [];

    const showComponent = computed(
      () => route.query.partId === props.identifier
    );

    function parseSections() {
      const items =
        root.value?.querySelectorAll('div[data-tag="assessmentSection"]') ?? [];

      for (let i = 0; i < items.length; i++) {
        const id = items[i].getAttribute("identifier");
        if (id) sectionIds.push(id);
      }
    }

    function onNavigation(to: Route["query"]) {
      if (to.partId === props.identifier) {
        const query = { ...to };

        const display = root.value?.querySelector(
          'div[data-tag="crmx"][data-name="nonlinear"][data-value="page"]'
        )
          ? "page"
          : [];

        let push = false;
        if (query.display !== display) {
          query.display = display;
          push = true;
        }

        if (!sectionIds.includes(String(query.sectionId))) {
          query.sectionId = sectionIds[0];
          push = true;
        }

        if (push) {
          router.replace({ query }).catch(() => {});
        }
      }
    }

    watch(
      () => route.query,
      (to, from) => {
        if (from && to.partId === from.partId) return;
        onNavigation(to);
      }
    );

    onMounted(() => {
      parseSections();
      onNavigation(route.query);
    });

    return {
      root,
      showComponent
    };
  }
});
