
import { defineComponent, ref, watch, useAttrs } from "vue";
import { storeToRefs } from "pinia";

import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";
const froalaSpace = () =>
  import(/* webpackChunkName: "froala" */ "@/components/froalaSpace.vue");

import { useStore } from "@/store/index";

import useResponseIdentifier, {
  makeResponseIdentifierProps
} from "@/composables/useResponseIdentifier";

export default defineComponent({
  name: "extendedTextInteraction",
  props: { ...makeResponseIdentifierProps() },

  components: {
    speechSynthesisBtn,
    froalaSpace
  },

  setup(props) {
    const store = useStore();
    const attrs = useAttrs();

    const maxWords =
      attrs["data-max-words"] !== undefined
        ? parseInt(attrs["data-max-words"] as string)
        : undefined;
    const fullscreen = attrs["data-fullscreen"] === "true";
    const wysiwyg = attrs["data-wysiwyg"] === "true";
    const minStrings =
      attrs["min-strings"] !== undefined
        ? parseInt(attrs["min-strings"] as string)
        : attrs["minStrings"] !== undefined
        ? parseInt(attrs["minStrings"] as string)
        : 0;

    const {
      storedValue,
      commitValue,
      registerResponseIdentifier,
      setResponseIdentifierRequired,
      responseValueRules,
      interactionInitialized
    } = useResponseIdentifier(props);

    const inputValue = ref(storedValue.value);

    const { revision, moduleType, readOnly } = storeToRefs(store);

    watch(inputValue, values => {
      commitValue(values);
    });

    watch(revision, revision => {
      if (revision == null) return;
      if (moduleType.value === "qti_editor") {
        inputValue.value = revision;
      } else {
        inputValue.value = revision.form[props.responseIdentifier];
      }
    });

    registerResponseIdentifier();
    setResponseIdentifierRequired(minStrings > 0);

    if (!wysiwyg) {
      interactionInitialized();
    }

    return {
      maxWords,
      fullscreen,
      wysiwyg,
      inputValue,
      readOnly,
      responseValueRules
    };
  }
});
