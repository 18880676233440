
import {
  defineComponent,
  inject,
  provide,
  ref,
  watch,
  computed,
  onMounted,
  useAttrs
} from "vue";
import { storeToRefs } from "pinia";

import { useStore } from "@/store/index";

import useResponseIdentifier, {
  makeResponseIdentifierProps
} from "@/composables/useResponseIdentifier";

import { shuffleArray, logger } from "@/helpers";
import {
  addClearSelectionCbKey,
  parentCheckboxChangedKey,
  parentResponseIdentifierKey,
  choiceTypeKey
} from "@/injectionKeys";

export default defineComponent({
  name: "choiceInteraction",

  props: { ...makeResponseIdentifierProps() },

  setup(props) {
    const store = useStore();
    const attrs = useAttrs();
    const root = ref<Element | null>(null);

    const shuffle = attrs["shuffle"] === "true";
    const maxChoices =
      attrs["max-choices"] !== undefined
        ? parseInt(attrs["max-choices"] as string)
        : attrs["maxChoices"] !== undefined
        ? parseInt(attrs["maxChoices"] as string)
        : 1;
    const minChoices =
      attrs["min-choices"] !== undefined
        ? parseInt(attrs["min-choices"] as string)
        : attrs["minChoices"] !== undefined
        ? parseInt(attrs["minChoices"] as string)
        : 0;
    const radioValue = ref(null);

    const {
      storedValue,
      commitValue,
      registerResponseIdentifier,
      setResponseIdentifierRequired,
      responseValueRules,
      interactionInitialized
    } = useResponseIdentifier(props);

    const { revision } = storeToRefs(store);

    const addClearSelectionCb = inject(addClearSelectionCbKey);

    const choiceType = computed(() => {
      if (maxChoices === 1) {
        return "radio";
      } else {
        return "checkbox";
      }
    });
    function clearSelection() {
      if (choiceType.value === "radio") {
        radioValue.value = null;
      } else {
        commitValue([]);
      }
    }

    function shuffleOptions() {
      const choices = Array.from(
        root.value!.querySelectorAll('[data-tag="simpleChoice"]')
      );
      if (choices.length === 0) {
        logger.warn("can't load choices");
        return;
      }
      const parentElement = choices[0].parentElement;
      const childElements: Element[] = [];
      const fixedElements: (number | Element)[][] = [];
      const otherElements: Element[] = [];
      choices.forEach((child, childIndex) => {
        {
          if (child.getAttribute("data-other") === "true") {
            otherElements.push(child);
          } else if (child.getAttribute("fixed") === "true") {
            fixedElements.push([childIndex, child]);
          } else {
            childElements.push(child);
          }
        }
      });

      shuffleArray(childElements);

      fixedElements.forEach(([index, element]) => {
        // insert fixed option at index
        childElements.splice(index as number, 0, element as Element);
      });

      childElements.forEach(el => parentElement!.appendChild(el));
      otherElements.forEach(el => parentElement!.appendChild(el));
    }

    function checkboxValueChange({ identifier, checked }: any) {
      let values = [];
      if (Array.isArray(storedValue.value)) {
        values = storedValue.value;
      }

      if (checked && !values.includes(identifier)) {
        commitValue([...values, identifier]);
      } else if (!checked && values.includes(identifier)) {
        commitValue(values.filter(id => id !== identifier));
      }
    }

    function radioValueChange(value: string | null) {
      commitValue(value);
    }

    provide(parentCheckboxChangedKey, checkboxValueChange);
    provide(choiceTypeKey, choiceType);
    provide(parentResponseIdentifierKey, props.responseIdentifier);

    watch(storedValue, value => {
      if (choiceType.value === "radio") radioValue.value = value;
    });

    watch(radioValue, value => {
      radioValueChange(value);
    });

    watch(revision, revision => {
      if (!revision || !revision.form) return;
      if (revision.form[props.responseIdentifier]) {
        commitValue(revision.form[props.responseIdentifier]);
        store.setOtherValue({
          responseIdentifier: props.responseIdentifier,
          value: revision.other[props.responseIdentifier]
        });
      } else clearSelection();
    });

    registerResponseIdentifier();
    setResponseIdentifierRequired(minChoices > 0);

    onMounted(() => {
      if (shuffle) {
        shuffleOptions();
      }

      if (choiceType.value === "radio") {
        radioValue.value = storedValue.value;
        addClearSelectionCb?.(clearSelection);
      }
      interactionInitialized();
    });

    return {
      shuffle,
      maxChoices,
      radioValue,
      root,
      responseValueRules,
      clearSelection
    };
  }
});
