
import { defineComponent, onMounted, ref, useAttrs, provide } from "vue";

import { useRoute, useRouter } from "@/router";
import { sendMessage } from "../plugins/messaging";

import { interactionInitializedKey } from "@/injectionKeys";

export default defineComponent({
  name: "assessmentTest",

  props: {
    title: String,
    identifier: { type: String, required: true }
  },

  setup() {
    const root = ref<HTMLDivElement | null>(null);
    const attrs = useAttrs();
    const route = useRoute();
    const router = useRouter();
    const toBeInitialized = ref(0);

    if (attrs["interaction-count"] !== undefined) {
      toBeInitialized.value = parseInt(String(attrs["interaction-count"]));
    }
    if (toBeInitialized.value === 0) sendMessage("INITIALIZED");

    function interactionInitialized() {
      toBeInitialized.value--;
      if (toBeInitialized.value === 0) sendMessage("INITIALIZED");
    }

    provide(interactionInitializedKey, interactionInitialized);

    onMounted(() => {
      if (!root.value) throw "invalid component root";
      const partIds: string[] = [];
      const items = root.value.querySelectorAll('div[data-tag="testPart"]');

      items.forEach(item => {
        const id = item.getAttribute("identifier");
        if (id) partIds.push(id);
      });

      if (!partIds.includes(String(route.query.partId))) {
        router.replace({
          query: { ...route.query, partId: partIds[0] }
        });
      }
    });
    return { root };
  }
});
