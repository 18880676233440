
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref,
  onMounted,
  useAttrs,
  onUnmounted
} from "vue";
import type { Ref } from "vue";
import { storeToRefs } from "pinia";

import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";

import useResponseIdentifier, {
  makeResponseIdentifierProps
} from "@/composables/useResponseIdentifier";

import { useStore } from "@/store/index";

import "@/polyfill/resizeObserver";

export default defineComponent({
  name: "textEntryInteraction",
  props: { ...makeResponseIdentifierProps() },

  components: {
    speechSynthesisBtn
  },

  setup(props) {
    const attrs = useAttrs();
    const store = useStore();
    const root = ref<HTMLDivElement | null>(null);
    const widthCalc = ref<HTMLSpanElement | null>(null);

    let obs: ResizeObserver | null = null;

    const data = reactive({
      inputValue: undefined as string | undefined,

      inputRules: undefined as undefined | Ref<((...args: any[]) => any)[]>
    });

    const { revision, readOnly } = storeToRefs(store);
    const {
      storedValue,
      commitValue,
      registerResponseIdentifier,
      setResponseIdentifierRequired,
      responseValueModified,
      responseValueRules,
      interactionInitialized
    } = useResponseIdentifier(props);

    const required = attrs["data-required"] === "true";
    const inline = attrs["data-input-inline"] !== "false";
    const filled = attrs["data-input-filled"] === "true";
    const dense = attrs["data-input-dense"] !== "false";
    const autoResize = attrs["data-input-autoresize"] === "true";
    const backgroundColor = attrs["data-background-color"] as string;
    const size =
      attrs["expected-length"] !== undefined
        ? attrs["expected-length"]
        : attrs.expectedLength;

    if (attrs["data-pattern-mask"]) {
      const patternMask = new RegExp(String(attrs["data-pattern-mask"]));
      const patternmaskMessage = String(attrs["data-patternmask-message"]);
      data.inputRules = [
        ...responseValueRules.value,
        (v: any) => {
          if (!responseValueModified) return true;
          if (!v) return true;
          if (patternMask!.test(v)) return true;
          return patternmaskMessage || false;
        }
      ];
    } else {
      data.inputRules = responseValueRules.value;
    }

    function resizeInput() {
      if (autoResize) {
        root.value!.style.setProperty(
          "--input-width",
          `${Math.max(widthCalc.value!.offsetWidth + 4, 24)}px`
        );
      }
    }

    watch(
      () => data.inputValue,
      values => {
        commitValue(values);
        if (widthCalc.value) widthCalc.value.innerText = values ?? "";
      }
    );

    watch(revision, revision => {
      if (!revision || !revision.form) return;
      if (revision.form[props.responseIdentifier]) {
        data.inputValue = revision.form[props.responseIdentifier];
      } else data.inputValue = undefined;
    });

    registerResponseIdentifier();
    setResponseIdentifierRequired(required);
    data.inputValue = storedValue.value;

    onUnmounted(() => {
      if (obs) obs.disconnect();
    });

    onMounted(() => {
      if (autoResize) {
        root.value!.style.setProperty("--input-width", `24px`);
        widthCalc.value!.innerText = data.inputValue ?? "";
        obs = new ResizeObserver(() => {
          resizeInput();
        });
        obs.observe(widthCalc.value!);
      }
      interactionInitialized();
    });

    return {
      inline,
      filled,
      dense,
      autoResize,
      backgroundColor,
      size,
      root,
      widthCalc,
      readOnly,
      ...toRefs(data)
    };
  }
});
