
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  watch,
  onMounted,
  useAttrs,
  markRaw
} from "vue";
import { storeToRefs } from "pinia";
import Sortable from "sortablejs";

import { useStore } from "@/store/index";

import useResponseIdentifier, {
  makeResponseIdentifierProps
} from "@/composables/useResponseIdentifier";

import { sendMessage } from "../plugins/messaging";
import { logger } from "@/helpers";

import {
  mdiPauseCircle,
  mdiRecordRec,
  mdiDelete,
  mdiDrag,
  mdiContentSave
} from "@mdi/js";

interface AudioInteractionMsg {
  responseIdentifier: string;
  mimeType: string;
  chunk?: Blob;
  state?: string;
}

let fixWebmMetaInfo = null as null | ((b: Blob) => Promise<Blob>);
const plyrAudio = () =>
  import(/* webpackChunkName: "plyr" */ "./plyrAudio.vue");

const icons = {
  mdiPauseCircle,
  mdiRecordRec,
  mdiDelete,
  mdiDrag,
  mdiContentSave
};
const TIMESLICE = 60000;

export default defineComponent({
  name: "audioInteraction",

  components: {
    plyrAudio
  },

  props: { ...makeResponseIdentifierProps() },

  setup(props) {
    const store = useStore();
    const attrs = useAttrs();
    const sectionsElement = ref<HTMLDivElement | null>(null);

    const required = attrs["data-required"] === "true";

    const mediaRecorder = ref<MediaRecorder | null>(null);

    const { revision, readOnly } = storeToRefs(store);

    const data = reactive({
      state: "inactive",
      //separate objects because I don't want to waste bandwidth downloading files i just uploaded
      sections: [] as {
        mimeType: string;
        data: string;
        timestamp: number;
      }[],
      values: [] as {
        mimeType: string;
        data: string;
        timestamp: number;
      }[],
      recordedChunks: [] as Blob[],
      objectURL: null as string | null,
      uploading: false,
      mimeType: "",
      timestamp: 0,
      errorMessage: null as null | string,
      error: false,
      bypass: false,

      cleanup: null as null | {
        resolve: (value?: unknown) => void;
        reject: (value: unknown) => void;
      },

      stopwatch: 0,
      interval: undefined as NodeJS.Timer | undefined,
      lastInterval: undefined as Date | undefined
    });

    const {
      storedValue,
      commitValue,
      registerResponseIdentifier,
      setResponseIdentifierRequired,
      interactionInitialized
    } = useResponseIdentifier(props);

    function clearCurrent() {
      if (!mediaRecorder.value) return;
      if (mediaRecorder.value.state !== "inactive") {
        data.bypass = true;
        mediaRecorder.value.stop();
      }
      const msgdata: AudioInteractionMsg = {
        responseIdentifier: props.responseIdentifier,
        mimeType: data.mimeType,
        state: "deleted"
      };
      sendMessage("AUDIO_INTERACTION", msgdata).catch(failure => {
        logger.warn(failure.message);
        //doesn't matter to user
      });
      data.objectURL = null;
      data.recordedChunks = [];
      data.error = false;
    }

    function retry() {
      if (!mediaRecorder.value) return;
      if (mediaRecorder.value.state === "inactive") {
        const msgdata: AudioInteractionMsg = {
          responseIdentifier: props.responseIdentifier,
          mimeType: data.mimeType
          //assume that actual autosave can't fail, just upload
        };
        return onFinish(msgdata);
      }
    }

    function onFinish(msgdata: AudioInteractionMsg) {
      msgdata.state = "finished";
      data.uploading = true;

      sendMessage("AUDIO_INTERACTION", msgdata)
        .then(result => {
          if (result) {
            const timestamp = data.timestamp;
            data.values = [
              ...data.values,
              {
                mimeType: data.mimeType,
                data: result.data,
                timestamp: timestamp
              }
            ];

            const blob = new Blob(data.recordedChunks, {
              type: data.mimeType
            });
            if (data.mimeType.startsWith("audio/webm") && fixWebmMetaInfo) {
              fixWebmMetaInfo(blob).then(b => {
                data.sections.push({
                  mimeType: data.mimeType,
                  data: URL.createObjectURL(b),
                  timestamp: timestamp
                });
              });
            } else {
              data.sections.push({
                mimeType: data.mimeType,
                data: URL.createObjectURL(blob),
                timestamp: timestamp
              });
            }
          }
          data.uploading = false;
          data.error = false;
          data.objectURL = null;
          data.recordedChunks = [];
          if (data.cleanup) {
            data.cleanup.resolve();
            data.cleanup = null;
          }
        })
        .catch(failure => {
          logger.warn(failure.message);
          data.errorMessage = failure.message;
          data.error = true;
          data.uploading = false;
          if (data.cleanup) {
            data.cleanup.reject({
              code: failure.code,
              message: failure.message
            });
            data.cleanup = null;
          }
        });
    }

    function deleteSection(id: number) {
      data.values = data.values.filter(v => v.timestamp !== id);
      data.sections = data.sections.filter(v => v.timestamp !== id);
    }

    function startBtn() {
      if (!mediaRecorder.value) {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then(stream => {
            data.recordedChunks = [];
            logger.log("creating recorder");
            mediaRecorder.value = markRaw(new MediaRecorder(stream));
            mediaRecorder.value.addEventListener("dataavailable", e => {
              logger.log("available", e.data.type);
              if (data.bypass) return;
              data.mimeType = e.data.type;
              const msgdata: AudioInteractionMsg = {
                responseIdentifier: props.responseIdentifier,
                mimeType: data.mimeType,
                chunk: e.data
              };
              if (e.data.size > 0) {
                data.recordedChunks.push(e.data);
              }
              if ("paused" === mediaRecorder.value!.state) {
                const blob = new Blob(data.recordedChunks, {
                  type: data.mimeType
                });
                if (data.mimeType.startsWith("audio/webm") && fixWebmMetaInfo) {
                  fixWebmMetaInfo(blob).then(b => {
                    data.objectURL = URL.createObjectURL(b);
                  });
                } else {
                  data.objectURL = URL.createObjectURL(blob);
                }
              }
              switch (mediaRecorder.value!.state) {
                case "inactive":
                  onFinish(msgdata);
                  break;
                default:
                  if (e.data.size <= 0) break;
                  msgdata.state = "recording";
                  sendMessage("AUDIO_INTERACTION", msgdata)
                    .then(() => {
                      data.error = false;
                    })
                    .catch(failure => {
                      logger.warn(failure.message);
                      data.errorMessage = failure.message;
                      data.error = true;
                    });
              }
            });
            mediaRecorder.value.addEventListener("stop", () => {
              logger.log("onStop");
              store.setSectionLocked(false);
              clearInterval(data.interval);
              data.stopwatch = 0;
              data.state = mediaRecorder.value!.state;
            });
            // ipad won't work if requestData is anywhere before pause is finished
            // old ff will fail if called after pause, just ignore the error
            // everything else will send empty data that will be ignored above
            mediaRecorder.value.addEventListener("pause", () => {
              try {
                mediaRecorder.value?.requestData();
                // eslint-disable-next-line no-empty
              } catch {}
            });
            mediaRecorder.value.addEventListener("start", () => {
              logger.log("onStart");
              store.setSectionLocked(true);
              data.lastInterval = new Date();
              data.stopwatch = 0;
              data.interval = setInterval(updateStopwatch, 1000);
              data.bypass = false;
              data.recordedChunks = [];
              data.timestamp = new Date().getTime();
              data.state = mediaRecorder.value!.state;
            });
            mediaRecorder.value.start(TIMESLICE);
          })
          .catch(err => {
            logger.warn(err);
          });
      } else if (mediaRecorder.value.state === "paused") {
        mediaRecorder.value.resume();
        logger.log("onResume");
        store.setSectionLocked(true);
        data.lastInterval = new Date();
        data.interval = setInterval(updateStopwatch, 1000);
        data.state = mediaRecorder.value.state;
      } else {
        mediaRecorder.value.start(TIMESLICE);
      }
    }

    function pauseBtn() {
      store.setSectionLocked(false);
      logger.log("onPause");
      if (!mediaRecorder.value) return;
      clearInterval(data.interval);
      updateStopwatch();
      if (mediaRecorder.value.requestData) {
        mediaRecorder.value.requestData();
      }
      mediaRecorder.value.pause();
      data.state = mediaRecorder.value.state;
    }
    function stopBtn() {
      mediaRecorder.value?.stop();
    }
    function updateStopwatch() {
      const now = new Date();
      data.stopwatch += now.getTime() - (data.lastInterval?.getTime() ?? 0);
      data.lastInterval = now;
    }

    watch(
      () => data.values,
      value => {
        commitValue(value);
      }
    );

    watch(storedValue, v => {
      // if updated from elsewhere
      if (v && v !== data.values) {
        data.values = v;
        data.sections = [...data.values];
      }
    });

    watch(revision, revision => {
      if (!revision || !revision.form) return;
      if (revision.form[props.responseIdentifier]) {
        data.values = revision.form[props.responseIdentifier];
        data.sections = [...data.values];
      } else {
        data.sections = [];
        data.values = [];
      }
    });

    if (!window.MediaRecorder) {
      import("@/polyfill/audioPoly").then(() => {
        logger.log("loaded audio polyfill");
      });
    }
    if (window.chrome) {
      import(/* webpackChunkName: "webmfix" */ "fix-webm-metainfo").then(f => {
        fixWebmMetaInfo = f.default;
        logger.log("loaded webm meta fix");
      });
    }

    registerResponseIdentifier();
    setResponseIdentifierRequired(required);
    data.values = storedValue.value || [];
    data.sections = [...data.values];

    onMounted(() => {
      Sortable.create(sectionsElement.value!, {
        handle: ".handle",
        onEnd: ({ oldIndex, newIndex }) => {
          if (oldIndex == newIndex || oldIndex == null || newIndex == null) {
            return;
          }
          let [el] = data.sections.splice(oldIndex, 1);
          data.sections = [
            ...data.sections.slice(0, newIndex),
            el,
            ...data.sections.slice(newIndex)
          ];
          [el] = data.values.splice(oldIndex, 1);
          data.values = [
            ...data.values.slice(0, newIndex),
            el,
            ...data.values.slice(newIndex)
          ];
        }
      });

      store.addCleanupCallback(() => {
        if (
          !mediaRecorder.value ||
          (mediaRecorder.value.state === "inactive" && !data.error)
        ) {
          return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
          if (mediaRecorder.value!.state !== "inactive") {
            mediaRecorder.value!.stop();
          }
          //error
          else {
            retry();
          }
          data.cleanup = { resolve, reject };
        });
      });

      interactionInitialized();
    });

    return {
      icons,
      sectionsElement,
      required,
      readOnly,
      clearCurrent,
      retry,
      deleteSection,
      startBtn,
      pauseBtn,
      stopBtn,
      ...toRefs(data)
    };
  }
});
