import "./main";
import router from "./router";

/**
 * Include development/staging environment routes in a custom file so the
 * chunks won't be included in the production build.
 *
 * Webpack entrypoint is modified based on env variables in vue.config.js
 */

router.addRoute({
  path: "/local/:name",
  name: "local-loader",
  component: () =>
    import(
      /* webpackChunkName: "testing" */
      /* webpackMode: "lazy" */
      "./testing/LocalLoader.vue"
    )
});

router.addRoute({
  path: "/remote/:package/:identifier",
  name: "remote-loader",
  component: () =>
    import(
      /* webpackChunkName: "testing" */
      /* webpackMode: "lazy" */
      "./testing/RemoteLoader.vue"
    )
});
