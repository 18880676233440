
import Vue from "vue";
import { defineComponent, computed, markRaw, VNode, ref } from "vue";
import axios from "axios";
import { useStore } from "@/store/index";
import { useRoute } from "@/router";
import { logger } from "@/helpers";

export default defineComponent({
  name: "Assessment",
  setup() {
    const store = useStore();
    // circular dependency is confusing TS
    const route: any = useRoute();
    const pkg = computed(() => route.params.package);
    const identifier = computed(() => route.params.identifier);

    const compiled = ref(
      null as {
        render(createElement: typeof Vue.prototype.$createElement): VNode;
      } | null
    );

    axios
      .get(`/qti/v1/static/${pkg.value}/${identifier.value}.vxml`, {
        responseType: "text",
        timeout: 60000,
        headers: {
          authorization: `Bearer ${store.token}`
        }
      })
      .then(response => {
        compiled.value = markRaw(Vue.compile(response.data));
        store.setTemplateCompiled(true);
      })
      .catch(failure => {
        logger.warn(failure);
        if (failure.message) {
          compiled.value = markRaw(
            Vue.compile(`<p>Something went wrong (${failure.message})</p>`)
          );
        } else {
          compiled.value = markRaw(Vue.compile(`<p>Critical error</p>`));
        }
      });
    return { pkg, identifier, compiled };
  },
  // vue router 3 doesn't expose onBeforeRouteLeave I could use in setup
  beforeRouteLeave(to, from, next) {
    if (!to.params.forced && to.name !== "assessment") {
      next(false);
    } else next();
  }
});
