import { ComputedRef, computed } from "vue";
// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
const mode = (process.env.VUE_APP_MODE || process.env.NODE_ENV) as string;

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const logger = {
  log(...args: any[]): void {
    if (["production", "test"].indexOf(mode) === -1) {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  },
  warn(...args: any[]): void {
    if (["production", "test"].indexOf(mode) === -1) {
      // eslint-disable-next-line no-console
      console.warn(...args);
    }
  }
};

// Debounce function with delay and a max limit before it must be executed.
// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (func: Function, delay: number, limit?: number) => {
  let inDebounce: any;
  let lastCall: number = new Date().getTime();
  return {
    // Call the debounced function.
    call(...args: []) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;
      clearTimeout(inDebounce);

      if (
        !!limit &&
        inDebounce !== undefined &&
        Math.abs(new Date().getTime() - lastCall) > limit
      ) {
        // Handle cases where the debounced function gets called repeatedly
        // before the delay and thus never actually executes the debounced function.
        // The limit sets the maximum wait until it will immediately call the
        // debounced function.
        lastCall = new Date().getTime();
        func.apply(context, args);
      } else {
        if (inDebounce === undefined) {
          // First call since the last execution, set last execution to now so
          // the limit can be applied correctly.
          lastCall = new Date().getTime();
        }

        inDebounce = setTimeout(() => {
          inDebounce = undefined;
          lastCall = new Date().getTime();
          func.apply(context, args);
        }, delay);
      }
    },

    // Clear any awaiting calls that have been debounced.
    clear() {
      clearTimeout(inDebounce);
      inDebounce = undefined;
    }
  };
};

export function generateGetters<T>(state: T) {
  const getters = {} as {
    [id in keyof T]: ComputedRef<T[id]>;
  };

  for (const k in state) {
    const index = k as keyof T;
    getters[index] = computed(() => state[index]);
  }
  return getters;
}

export const requireLocales = () => {
  return require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
};

export default {
  shuffleArray,
  logger,
  generateGetters
};
