
import { mdiBugStop } from "@mdi/js";
import { defineComponent, computed } from "vue";
import { useRoute } from "@/router";
const icons = { mdiBugStop };

export default defineComponent({
  name: "Error",
  setup() {
    const route: any = useRoute();
    const error = computed(() => {
      return route.params.error;
    });
    return {
      error,
      icons
    };
  }
});
