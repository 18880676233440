import { logger } from "@/helpers";

type callbackList = {
  [index: string]: {
    resolve: (value: unknown) => void;
    reject: (reason?: any) => void;
    timeoutId: NodeJS.Timeout;
  };
};
const callbacks: callbackList = {};
const handlers: { [id: string]: ((event: MessageEvent<any>) => void)[] } = {};
let messagePort: MessagePort;

const defaultTimeout = 60000;

const _postMessage = (
  type: string,
  messageId?: string,
  data?: any,
  name?: string,
  transferList: Transferable[] = []
): void => {
  const payload = {
    type: type,
    name: name,
    data: data,
    messageId: messageId
  };
  logger.log("qti-frontend/_postMessage", payload);
  messagePort.postMessage(payload, transferList);
};

export const resolveMessage = (messageId: string, data?: any): void => {
  if (messageId === undefined) return;
  _postMessage("RESOLVE", messageId, data, "RESOLVE");
};

export const rejectMessage = (messageId: string, error: Object): void => {
  if (messageId === undefined) return;
  _postMessage("REJECT", messageId, error, "REJECT");
};

export const sendMessage = async (
  name: string,
  data?: any,
  transferList?: Transferable[],
  timeout?: number
): Promise<any> => {
  if (!messagePort) {
    return Promise.reject({
      code: "message_port_error",
      message: "message port not initialized"
    });
  }

  if (timeout === 0) {
    _postMessage("MESSAGE", undefined, data, name, transferList);
    return Promise.resolve();
  }

  if (timeout === undefined) {
    timeout = defaultTimeout;
  }

  return new Promise((resolve, reject) => {
    const messageId = `${Math.floor(Math.random() * 1000000000)}`;

    const timeoutId = setTimeout(() => {
      if (callbacks[messageId]) {
        callbacks[messageId].reject({
          code: "timeout_error",
          message: "message timeout"
        });
        delete callbacks[messageId];
      }
    }, timeout);
    callbacks[messageId] = { resolve, reject, timeoutId };
    _postMessage("MESSAGE", messageId, data, name, transferList);
  });
};

export const syncMessage = async (
  name: string,
  data?: any,
  transferList?: []
) => {
  return sendMessage(name, data, transferList, 0);
};

export const addEventListener = (
  name: string,
  handler: (event: MessageEvent<any>) => void
) => {
  if (!handlers[name]) handlers[name] = [];
  handlers[name].push(handler);
};

export const removeEventListener = (
  name: string,
  handler: (event: MessageEvent<any>) => void
) => {
  if (!handlers[name]) return;
  handlers[name] = handlers[name].filter(v => v != handler);
};

export const clearEventListeners = () => {
  for (const h in handlers) {
    delete handlers[h];
  }
};

export const messageHandler = (event: MessageEvent<any>) => {
  logger.log("qti-frontend/messageHandler", event);

  if (event.data === "CHANNEL" && event.ports.length > 0) {
    messagePort = event.ports[0];
    messagePort.onmessage = messageHandler;
  }

  const type = event.data.type;
  const name = event.data.name;
  switch (type) {
    case "REJECT":
      if (callbacks[event.data.messageId]) {
        clearTimeout(callbacks[event.data.messageId].timeoutId);
        callbacks[event.data.messageId].reject(event.data.data);
      }
      delete callbacks[event.data.messageId];
      break;
    case "RESOLVE":
      if (callbacks[event.data.messageId]) {
        clearTimeout(callbacks[event.data.messageId].timeoutId);
        callbacks[event.data.messageId].resolve(event.data.data);
      }
      delete callbacks[event.data.messageId];
      break;
  }

  if (!name) return;
  if (handlers[name]) for (const h of handlers[name]) h(event);
};

export default {
  syncMessage,
  sendMessage,
  messageHandler,
  addEventListener,
  resolveMessage,
  rejectMessage,
  removeEventListener,
  clearEventListeners
};
