import type { InjectionKey, ComputedRef, Ref } from "vue";
import { MatchOptionDataType } from "@/types/components";

export const addClearSelectionCbKey = Symbol() as InjectionKey<
  (fn: () => void) => void
>;
export const interactionInitializedKey = Symbol() as InjectionKey<() => void>;
export const assessmentItemIdKey = Symbol() as InjectionKey<string>;

export const parentCheckboxChangedKey = Symbol() as InjectionKey<
  ({ identifier, checked }: any) => void
>;
export const parentResponseIdentifierKey = Symbol() as InjectionKey<string>;

export const choiceTypeKey = Symbol() as InjectionKey<
  ComputedRef<"radio" | "checkbox">
>;

export const dragCardKey = Symbol() as InjectionKey<Ref<boolean>>;
export const tabularKey = Symbol() as InjectionKey<Ref<boolean>>;
export const matchRowsKey = Symbol() as InjectionKey<
  Ref<MatchOptionDataType[]>
>;
export const matchOptionsKey = Symbol() as InjectionKey<
  Ref<MatchOptionDataType[]>
>;
export const matchOptionErrorKey = Symbol() as InjectionKey<
  Ref<{ [id: string]: boolean }>
>;
export const onDropKey = Symbol() as InjectionKey<
  (optionId: string, data?: any) => void
>;
export const onMenuClickKey = Symbol() as InjectionKey<
  (rowId: string, optionId: string) => void
>;
export const onMatchClickRemoveKey = Symbol() as InjectionKey<
  (rowId: string, optionId: string) => void
>;
export const onChoiceClickKey = Symbol() as InjectionKey<
  (rowId: string) => void
>;
export const dragEventKey = Symbol() as InjectionKey<Ref<any | null>>;
export const choiceMenuOptionsKey = Symbol() as InjectionKey<
  ComputedRef<{
    [index: string]: Partial<MatchOptionDataType[]>;
  }>
>;
export const choiceCardVisibilityKey = Symbol() as InjectionKey<
  ComputedRef<{ [index: string]: boolean }>
>;
