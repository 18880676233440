
import {
  defineComponent,
  reactive,
  toRefs,
  inject,
  ref,
  watch,
  onMounted,
  useAttrs
} from "vue";
import { storeToRefs } from "pinia";
import Konva from "konva";

import { useStore } from "@/store/index";

import useResponseIdentifier, {
  makeResponseIdentifierProps
} from "@/composables/useResponseIdentifier";

import { addClearSelectionCbKey } from "@/injectionKeys";

export default defineComponent({
  name: "hotspotInteraction",
  props: { ...makeResponseIdentifierProps() },

  setup(props) {
    const root = ref<HTMLDivElement | null>(null);
    const store = useStore();
    const attrs = useAttrs();
    const hotspot = ref<HTMLDivElement | null>(null);

    let stage: Konva.Stage | null = null;

    const minChoices =
      attrs["min-choices"] !== undefined
        ? parseInt(attrs["min-choices"] as string)
        : attrs["minChoices"] !== undefined
        ? parseInt(attrs["minChoices"] as string)
        : 0;

    const data = reactive({
      imageSrc: undefined as string | undefined,
      imageWidth: undefined as number | undefined,
      imageHeight: undefined as number | undefined,

      showPointer: false,
      selectedId: undefined as string | undefined,

      choices: {} as { [index: string]: any }
    });

    const { revision, readOnly } = storeToRefs(store);

    const {
      storedValue,
      commitValue,
      registerResponseIdentifier,
      setResponseIdentifierRequired,
      interactionInitialized
    } = useResponseIdentifier(props);

    const addClearSelectionCb = inject(addClearSelectionCbKey);

    function clearSelection() {
      if (!storedValue.value) return;
      const selected = data.choices[storedValue.value as string];
      if (selected) {
        selected.opacity(0);
        selected.stroke("#000000");
        selected.getLayer().draw();
      }
      commitValue(null);
      data.selectedId = undefined;
    }

    function init() {
      stage = new Konva.Stage({
        container: hotspot.value!,
        width: data.imageWidth,
        height: data.imageHeight
      });

      stage.preventDefault(false);
      const layer = new Konva.Layer();

      stage.add(layer);

      const imageObj = new Image(data.imageWidth, data.imageHeight);
      imageObj.crossOrigin = "Anonymous";
      imageObj.src = data.imageSrc!;
      imageObj.onload = function () {
        const backgroundImage = new Konva.Image({
          x: 0,
          y: 0,
          width: data.imageWidth,
          height: data.imageHeight,
          image: imageObj
        });
        layer.add(backgroundImage);
        backgroundImage.preventDefault(false);
        backgroundImage.moveToBottom();
        layer.draw();

        interactionInitialized();
      };

      const elements = root.value!.querySelectorAll(
        "div[data-tag='hotspotChoice']"
      );
      for (let i = 0; i < elements.length; i++) {
        const shape = elements[i].getAttribute("shape");
        const identifier = elements[i].getAttribute("identifier")!;
        const coords = elements[i].getAttribute("coords")!.split(",");
        let el;
        const config = {
          stroke: "#000000",
          strokeWidth: 1,
          opacity: 0,
          x: parseInt(coords[0]),
          y: parseInt(coords[1])
        };
        if (shape === "ellipse") {
          el = new Konva.Ellipse({
            ...config,
            radiusX: parseInt(coords[2]),
            radiusY: parseInt(coords[3])
          });
        } else if (shape === "circle") {
          el = new Konva.Circle({ ...config, radius: parseInt(coords[2]) });
        } else if (shape === "poly") {
          const points: number[] = [];
          coords.forEach(n => points.push(parseInt(n)));
          el = new Konva.Line({
            ...config,
            closed: true,
            points: points,
            position: { x: 0, y: 0 }
          });
        } else {
          el = new Konva.Rect({
            ...config,
            size: {
              width: parseInt(coords[2]) - parseInt(coords[0]),
              height: parseInt(coords[3]) - parseInt(coords[1])
            }
          });
        }
        el.on("mouseenter", () => {
          onMouseEnter(identifier);
        });
        el.on("mouseleave", () => {
          onMouseLeave(identifier);
        });
        el.on("click tap", () => {
          onMouseClick(identifier);
        });
        layer.add(el);
        data.choices[identifier] = el;
      }

      if (storedValue.value) {
        onMouseClick(storedValue.value);
      }
    }

    function onMouseEnter(id: string) {
      data.choices[id].opacity(1);
      if (id !== data.selectedId) {
        data.showPointer = true;
      }
      data.choices[id].getLayer().draw();
    }

    function onMouseLeave(id: string) {
      if (data.selectedId !== id) data.choices[id].opacity(0);
      data.showPointer = false;
      data.choices[id].getLayer().draw();
    }

    function onMouseClick(id: string) {
      if (data.selectedId !== id && !!data.choices[id]) {
        data.choices[id].stroke("#ff0000");
        data.choices[id].opacity(1);
        if (data.selectedId && data.choices[data.selectedId]) {
          data.choices[data.selectedId].opacity(0);
          data.choices[data.selectedId].stroke("#000000");
        }
        data.selectedId = id;
        data.showPointer = false;
        data.choices[id].getLayer().draw();
        commitValue(id);
      }
    }

    watch(revision, revision => {
      if (!revision || !revision.form) return;
      if (revision.form[props.responseIdentifier]) {
        const id = revision.form[props.responseIdentifier];
        onMouseClick(id);
      } else clearSelection();
    });

    watch(readOnly, value => {
      stage?.listening(!value);
    });

    registerResponseIdentifier();
    setResponseIdentifierRequired(minChoices > 0);

    onMounted(() => {
      if (!root.value) throw "invalid component root";
      const object = root.value.querySelector("object");
      if (object != null) {
        data.imageSrc = object.data;
        data.imageWidth = parseInt(object!.width);
        data.imageHeight = parseInt(object!.height);
        init();
      }
      addClearSelectionCb?.(clearSelection);
    });

    return {
      root,
      hotspot,
      readOnly,
      clearSelection,
      ...toRefs(data)
    };
  }
});
