
import {
  defineComponent,
  reactive,
  toRefs,
  inject,
  ref,
  watch,
  onMounted,
  useAttrs
} from "vue";
import { storeToRefs } from "pinia";
import { VSelect } from "vuetify/lib/components";

import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";

import { useStore } from "@/store/index";

import useResponseIdentifier, {
  makeResponseIdentifierProps
} from "@/composables/useResponseIdentifier";

import { shuffleArray } from "@/helpers";
import { addClearSelectionCbKey } from "@/injectionKeys";

export default defineComponent({
  name: "inlineChoiceInteraction",

  props: { ...makeResponseIdentifierProps() },

  components: {
    speechSynthesisBtn
  },

  setup(props) {
    const root = ref<HTMLDivElement | null>(null);
    const store = useStore();
    const attrs = useAttrs();
    const select = ref<InstanceType<typeof VSelect> | null>(null);

    const shuffle = attrs["shuffle"] === "true";
    const required = attrs["required"] === "true";
    const inline = attrs["data-input-inline"] !== "false";
    const filled = attrs["data-input-filled"] === "true";
    const dense = attrs["data-input-dense"] !== "false";

    const {
      storedValue,
      commitValue,
      registerResponseIdentifier,
      setResponseIdentifierRequired,
      responseValueRules,
      interactionInitialized
    } = useResponseIdentifier(props);

    const data = reactive({
      selectedValue: undefined as undefined | string,
      items: [] as { text: string; value: string }[]
    });

    const addClearSelectionCb = inject(addClearSelectionCbKey);

    const { revision, readOnly } = storeToRefs(store);

    function clearSelection() {
      data.selectedValue = undefined;
    }

    function inlineElement() {
      root.value!.style.display = "inline-block";
      (select.value!.$el as HTMLElement).style.display = "inline-block";
    }

    function parseOptions() {
      if (!root.value) throw "invalid component root";
      // parse options placed in the slot
      const optionElements = root.value.querySelectorAll("option");

      const options: any[] = [];
      const fixedOptions = [];

      for (let i = 0; i < optionElements.length; i++) {
        const item = {
          text: optionElements[i].innerText,
          value: optionElements[i].value
        };

        if (optionElements[i].getAttribute("fixed") === "true") {
          fixedOptions.push([i, item]);
        } else {
          options.push(item);
        }
      }

      if (shuffle) {
        shuffleArray(options);
      }

      fixedOptions.forEach(([index, item]) => {
        // insert fixed option at index
        options.splice(index as number, 0, item);
      });

      data.items = options;
    }

    watch(revision, revision => {
      if (!revision || !revision.form) return;
      if (revision.form[props.responseIdentifier]) {
        data.selectedValue = revision.form[props.responseIdentifier];
      } else clearSelection();
    });
    watch(
      () => data.selectedValue,
      value => {
        commitValue(value);
      }
    );

    registerResponseIdentifier();
    setResponseIdentifierRequired(required ?? false);

    onMounted(() => {
      if (inline) {
        // element should be inline
        inlineElement();
      }

      parseOptions();
      data.selectedValue = storedValue.value;
      addClearSelectionCb?.(clearSelection);
      interactionInitialized();
    });

    return {
      select,
      readOnly,
      root,
      filled,
      dense,
      responseValueRules,
      clearSelection,
      ...toRefs(data)
    };
  }
});
