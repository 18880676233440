
import { defineComponent, reactive, toRefs, ref, nextTick, watch } from "vue";
import { storeToRefs } from "pinia";
import { VList } from "vuetify/lib/components";

import { useStore } from "@/store/index";

import { logger } from "@/helpers";
import messaging from "@/plugins/messaging";

export default defineComponent({
  name: "revisions-drawer",

  props: {
    value: Boolean
  },

  setup(props, ctx) {
    const store = useStore();
    const list = ref<InstanceType<typeof VList> | null>(null);

    const data = reactive({
      revisionLoading: false,
      latest: null as null | string,
      generation: null as string | null,
      generations: [] as {
        generation: string;
        timestamp: string;
        metadata: {
          char_count?: string;
          word_count?: string;
          questions?: string;
          answered?: string;
        };
      }[]
    });

    const { moduleType } = storeToRefs(store);

    function close() {
      store.setRevisionDialog(false);
    }

    function activate() {
      close();
      if (data.latest !== data.generation) ctx.emit("activated");
    }

    function revisionLoad(generation: string) {
      if (generation === data.generation) return;
      data.revisionLoading = true;

      messaging
        .sendMessage("CONTENT_GET", {
          generation: generation
        })
        .then(result => {
          store.setRevision(result.content);
          data.generation = generation;
        })
        .catch(failure => {
          logger.warn(failure.message);
        })
        .finally(() => {
          data.revisionLoading = false;
        });
    }

    function updateVersionHistory() {
      data.revisionLoading = true;
      data.generation = null;
      data.latest = null;
      messaging
        .sendMessage("CONTENT_GENERATIONS", {})
        .then(result => {
          if (result) {
            data.generations = result.generations.slice().reverse();
            if (data.generations.length > 0) {
              data.generation = data.generations[0].generation;
              data.latest = data.generations[0].generation;
              nextTick(() => {
                const firstChild = list.value!.$el.firstChild as HTMLElement;
                firstChild.focus();
              });
            } else close();
          }
        })
        .catch(failure => {
          logger.warn(failure.message);
          close();
        })
        .finally(() => {
          data.revisionLoading = false;
        });
    }

    watch(
      () => props.value,
      value => {
        if (value) {
          updateVersionHistory();
        }
      }
    );

    return {
      list,
      moduleType,
      revisionLoad,
      activate,
      ...toRefs(data)
    };
  }
});
