
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { Route } from "vue-router";

import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";

import { useRoute, useRouter } from "@/router";
import { useStore } from "@/store/index";
import { shuffleArray } from "@/helpers";

import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiBookmark,
  mdiBookmarkOutline,
  mdiCheckboxOutline,
  mdiCheckboxBlankOutline,
  mdiInformationOutline,
  mdiTableOfContents,
  mdiHelpCircleOutline
} from "@mdi/js";

const icons = {
  mdiChevronLeft,
  mdiChevronRight,
  mdiBookmark,
  mdiBookmarkOutline,
  mdiCheckboxOutline,
  mdiCheckboxBlankOutline,
  mdiInformationOutline,
  mdiTableOfContents,
  mdiHelpCircleOutline
};

export default defineComponent({
  name: "assessmentSection",

  props: {
    title: String,
    identifier: String
  },

  components: {
    speechSynthesisBtn
  },

  setup(props) {
    const store = useStore();
    const items = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();

    const assessmentItems = ref<
      {
        identifier: string;
        title: string;
      }[]
    >([]);

    const showComponent = computed(
      () => route.query.sectionId === props.identifier
    );

    const {
      bookmarks,
      responseAnswered,
      responseValid,
      readOnly,
      sectionLocked,
      assessmentItemResponseIdentifiers
    } = storeToRefs(store);

    const currentItemIndex = computed(() =>
      assessmentItems.value.findIndex(
        item => item.identifier === route.query.itemId
      )
    );

    function shuffle() {
      const elements = Array.from(
        items.value?.querySelectorAll('div[data-tag="assessmentItem"]') ?? []
      );

      shuffleArray(elements);
      elements.forEach(el => items.value?.appendChild(el));
    }

    function toggleBookmark() {
      const itemId = String(route.query.itemId);

      let _bookmarks;
      if (bookmarks.value.includes(itemId)) {
        _bookmarks = bookmarks.value.filter(id => id !== itemId);
      } else {
        _bookmarks = [...bookmarks.value, itemId];
      }

      store.setBookmarks(_bookmarks!);
    }

    function gotoQuestion(itemId: string) {
      const query: Route["query"] = { ...route.query, itemId };
      delete query.overview;
      router.replace({ query });
    }

    function parseItems() {
      const aItems = items.value?.querySelectorAll(
        'div[data-tag="assessmentItem"]'
      );
      if (!aItems) return;
      for (let i = 0; i < aItems.length; i++) {
        assessmentItems.value.push({
          identifier: aItems[i].getAttribute("identifier")!,
          title: aItems[i].getAttribute("data-title")!
        });
      }
    }

    function onNavigation(to: Route["query"]) {
      if (to.sectionId === props.identifier) {
        if (!assessmentItems.value.some(i => i.identifier === to.itemId)) {
          const itemId = assessmentItems.value[0].identifier;
          router.replace({ query: { ...to, itemId } });
        }
      }
    }

    function prevItem() {
      if (currentItemIndex.value > 0) {
        router.replace({
          query: {
            ...route.query,
            itemId: assessmentItems.value[currentItemIndex.value - 1].identifier
          }
        });
      }
    }

    function nextItem() {
      if (currentItemIndex.value + 1 < assessmentItems.value.length) {
        router.replace({
          query: {
            ...route.query,
            itemId: assessmentItems.value[currentItemIndex.value + 1].identifier
          }
        });
      } else {
        router.replace({
          query: {
            ...route.query,
            overview: "true"
          }
        });
      }
    }

    watch(
      () => route.query,
      (to, from) => {
        if (from && to.sectionId === from.sectionId) return;
        onNavigation(to);
      }
    );

    onMounted(() => {
      const ordering = items.value?.querySelector('div[data-tag="ordering"]');
      if (ordering && ordering.getAttribute("shuffle") === "true") {
        shuffle();
      }

      // parse assessmentItems order after shuffle
      parseItems();
      onNavigation(route.query);
    });

    return {
      icons,
      showComponent,
      items,
      bookmarks,
      responseAnswered,
      responseValid,
      assessmentItemResponseIdentifiers,
      currentItemIndex,
      readOnly,
      sectionLocked,
      toggleBookmark,
      gotoQuestion,
      prevItem,
      nextItem,
      assessmentItems
    };
  }
});
