
import { defineComponent, computed } from "vue";

import { sendMessage } from "../plugins/messaging";
import { useStore } from "@/store/index";

import { mdiAccountVoice } from "@mdi/js";
const icons = {
  mdiAccountVoice
};

export default defineComponent({
  name: "speech-synthesis-btn",

  props: {
    text: String
  },

  setup(props) {
    const speechSynthesis = computed(() => useStore().speechSynthesis);

    function synthesize() {
      sendMessage("SYNTHESIZE_TEXT", {
        text: props.text != null ? props.text.trim() : ""
      });
    }

    return {
      icons,
      synthesize,
      speechSynthesis
    };
  }
});
