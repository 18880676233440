
import { defineComponent, onMounted, ref } from "vue";
import type { PropType } from "vue";

import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";

import { MatchOptionDataType } from "@/types/components";

import { mdiDrag } from "@mdi/js";

const icons = {
  mdiDrag
};

export default defineComponent({
  name: "choiceDragCard",

  components: {
    speechSynthesisBtn
  },

  props: {
    identifier: { type: String, required: true },
    readOnly: { type: Boolean, default: false },
    acceptableOptions: {
      type: Array as PropType<Array<MatchOptionDataType>>,
      required: false
    },
    visible: { type: Boolean, default: true }
  },

  emits: ["dragstart", "dragend", "menuclick", "click"],

  setup() {
    const slotData = ref<HTMLDivElement | null>(null);
    const text = ref<string | undefined>(undefined);
    onMounted(() => {
      text.value = slotData.value!.innerText;
    });
    return { icons, slotData, text };
  }
});
