
import { defineComponent, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n-composable";

import revisionsDrawer from "@/components/revisionsDrawer.vue";

import { useStore } from "@/store/index";
import { useRouter, useRoute } from "@/router/index";

import {
  sendMessage,
  syncMessage,
  messageHandler,
  addEventListener,
  resolveMessage,
  rejectMessage
} from "@/plugins/messaging";
import { allowedOrigins } from "@/debug";
import { logger } from "@/helpers";

export default defineComponent({
  name: "App",

  components: { revisionsDrawer },

  setup() {
    const store = useStore();
    const i18n = useI18n();
    const route = useRoute();
    const router = useRouter();

    const {
      templateCompiled,
      responseValues,
      otherValues,
      bookmarks,
      metadata,
      valid,
      revisionDialog,
      readOnly
    } = storeToRefs(store);

    const allowSyncValues = computed(() => {
      return !revisionDialog.value && templateCompiled.value;
    });

    function onError(error?: { message: string }) {
      if (route.path.includes("/local/") || route.path.includes("/remote/")) {
        logger.warn(error?.message);
        return;
      }
      router
        .replace({
          name: "error",
          params: {
            forced: "true",
            error: error?.message ?? ""
          }
        })
        .catch(() => {});
    }

    function activateRevision() {
      if (!readOnly.value) {
        const promises = [
          sendMessage(
            "VALUES",
            { values: responseValues.value },
            undefined,
            5000
          ),
          sendMessage(
            "OTHER_VALUES",
            {
              otherValues: otherValues.value
            },
            undefined,
            5000
          ),
          sendMessage("METADATA", { metadata: metadata.value }),
          sendMessage("VALID", { valid: valid.value })
        ];
        Promise.all(promises).catch(onError);
      }
    }

    function revisionInput(e: boolean) {
      if (revisionDialog.value != e) {
        store.setRevisionDialog(e);
      }
    }

    watch(responseValues, values => {
      if (allowSyncValues.value) {
        sendMessage("VALUES", { values }, undefined, 5000).catch(onError);
      }
    });

    watch(otherValues, otherValues => {
      if (allowSyncValues.value) {
        sendMessage("OTHER_VALUES", { otherValues }, undefined, 5000).catch(
          onError
        );
      }
    });

    watch(bookmarks, bookmarks => {
      if (allowSyncValues.value) {
        sendMessage("BOOKMARKS", { bookmarks });
      }
    });

    watch(metadata, metadata => {
      if (allowSyncValues.value) {
        sendMessage("METADATA", { metadata });
      }
    });

    watch(valid, valid => {
      if (allowSyncValues.value) {
        sendMessage("VALID", { valid }).catch(onError);
      }
    });

    window.addEventListener("keydown", event => {
      if (event.ctrlKey && event.key === "p") {
        event.preventDefault();
        syncMessage("PRINT");
      }
    });

    window.addEventListener("beforeprint", () => {
      syncMessage("PRINT");
    });

    addEventListener("START", (event: MessageEvent<any>) => {
      const data = event.data.data;

      store.setToken(data.token);
      store.setModuleType(data.moduleType);
      const locale = data.locale || "en";
      i18n.locale.value = locale;

      if (data.a11y) {
        if (data.a11y.backgroundColor) {
          document.body.classList.add(
            `a11y-bg-${data.a11y.backgroundColor.slice(1)}`
          );
        }

        if (data.a11y.speechSynthesis) {
          store.setSpeechSynthesis(true);
        }
        store.setSpeechRecognitionLanguage(data.a11y.speechRecognitionLanguage);

        store.setSpellCheckLanguage(data.a11y.spellCheckLanguage);
      }

      store.setHidden(!!data.hidden);

      if (data.values) {
        store.setResponseValues(data.values);
      }
      if (data.otherValues) {
        store.setOtherValues(data.otherValues);
      }
      if (data.bookmarks) {
        store.setBookmarks(data.bookmarks);
      }
      if (data.allowPrint) {
        store.setAllowPrint(true);
      }

      router.push({
        name: "assessment",
        params: {
          package: data.package,
          identifier: data.identifier
        }
      });
    });

    addEventListener("AUDIO_RECOVERED", (event: MessageEvent<any>) => {
      const data = event.data.data;
      const oldValue = responseValues.value[data.responseIdentifier] || [];
      const value = [
        ...oldValue,
        {
          mimeType: data.mimeType,
          data: data.data,
          timestamp: new Date().getTime()
        }
      ];
      store.setResponseIdentifierValue({
        responseIdentifier: data.responseIdentifier,
        value: value
      });
    });

    addEventListener("STOP", (event: MessageEvent<any>) => {
      const messageId = event.data.messageId;

      const promises: Promise<any>[] = [];
      store.cleanupCallbacks.forEach((c: () => Promise<any>) =>
        promises.push(c())
      );
      Promise.allSettled(promises).then(results => {
        const err = results.find(r => r.status === "rejected") as
          | PromiseRejectedResult
          | undefined;
        if (err) {
          rejectMessage(messageId, err.reason);
        } else {
          resolveMessage(messageId);
        }
      });
    });

    addEventListener("SUBMISSION_VALUES", (event: MessageEvent<any>) => {
      const values = store.submissionData;

      const messageId = event.data.messageId;
      resolveMessage(messageId, { values: values });
    });

    window.addEventListener("message", event => {
      if (allowedOrigins.includes(event.origin)) {
        messageHandler(event);
      }
    });

    addEventListener("HIDDEN", (event: MessageEvent<any>) => {
      if (event && event.data && event.data.data) {
        store.setHidden(!!event.data.data.hidden);
      }
    });

    return {
      revisionDialog,
      templateCompiled,
      activateRevision,
      revisionInput
    };
  }
});
