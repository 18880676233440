
import {
  defineComponent,
  reactive,
  computed,
  toRefs,
  inject,
  ref,
  nextTick,
  watch,
  markRaw
} from "vue";
import { storeToRefs } from "pinia";
import { useStore } from "@/store/index";
import useResponseIdentifier, {
  makeResponseIdentifierProps
} from "@/composables/useResponseIdentifier";

import {
  mdiClose,
  mdiMagnifyPlusOutline,
  mdiMagnifyMinusOutline
} from "@mdi/js";
import { sendMessage } from "../plugins/messaging";
import { addClearSelectionCbKey } from "@/injectionKeys";

import { UploadStage } from "../plugins/uploadStage";

import { logger } from "@/helpers";

import { VFileInput } from "vuetify/lib/components";

const icons = {
  mdiClose,
  mdiMagnifyPlusOutline,
  mdiMagnifyMinusOutline
};

export default defineComponent({
  name: "uploadInteraction",
  props: { ...makeResponseIdentifierProps() },

  setup(props, ctx) {
    const attrs = ctx.attrs;
    const imageFileInput = ref<InstanceType<typeof VFileInput> | null>(null);
    const editor = ref<HTMLDivElement | null>(null);
    const background = ref<HTMLImageElement | null>(null);
    const container = ref<HTMLDivElement | null>(null);
    const store = useStore();

    const stage = ref<null | UploadStage>(null);

    const data = reactive({
      loading: false,
      error: false,
      errorMessage: undefined,

      fileInput: undefined,
      image: null as null | File | Blob,
      imageURL: undefined as string | undefined,
      imageWidth: undefined as undefined | number,
      imageHeight: undefined as undefined | number,
      imageOptions: undefined as undefined | {},
      needsScaling: false,
      inputValue: null as { data: string; name: string } | null,

      scale: 1,
      minScale: 0,

      targetWidth: 610,
      targetHeight: 710
    });

    const required = attrs["data-required"] === "true";
    let editable = false;
    const preview = attrs["data-preview"] === "true";
    if (attrs["data-image-options"]) {
      const imageOptions = JSON.parse(String(attrs["data-image-options"]));
      editable = imageOptions.editable != null ? imageOptions.editable : false;
      if (imageOptions.max_width) data.targetWidth = imageOptions.max_width;
      if (imageOptions.max_height) {
        data.targetHeight = imageOptions.max_height;
      }
    }
    const type = attrs["type"]
      ? (attrs["type"] as string).replaceAll(" ", ",")
      : undefined;

    const {
      storedValue,
      commitValue,
      registerResponseIdentifier,
      setResponseIdentifierRequired,
      interactionInitialized
    } = useResponseIdentifier(props);

    const addClearSelectionCb = inject(addClearSelectionCbKey);

    const { revision, readOnly } = storeToRefs(store);

    const editDialog = computed(() => {
      if (data.inputValue || !editable || !data.imageURL) {
        return false;
      }
      return data.needsScaling;
    });

    function checkImageSize(width: number, height: number) {
      data.imageWidth = width;
      data.imageHeight = height;
      data.scale = 1;
      if (width > data.targetWidth || height > data.targetHeight) {
        data.needsScaling = true;
      } else if (!data.inputValue) {
        upload();
      }
    }

    function checkFileType(fileType: string) {
      if (!fileType || readOnly.value) return false;
      if (type) return type.includes(fileType.toString());
      else return fileType.toString().indexOf("image") === 0;
    }

    function onPaste(event: ClipboardEvent) {
      if (data.loading) return;
      data.error = false;
      if (data.imageURL || data.inputValue) return;
      for (let i = 0; i < event.clipboardData!.items.length; i++) {
        const item = event.clipboardData!.items[i];
        if (checkFileType(item.type)) {
          const blob = item.getAsFile()!;

          data.imageURL = URL.createObjectURL(blob);
          data.image = blob;
          break;
        }
      }
    }

    function onDrop(e: DragEvent) {
      if (data.loading) return;
      e.preventDefault();
      e.stopPropagation();
      data.error = false;
      // I have to fool ts for browser compat nonsense
      const targetFiles = (e.target as any).files;
      const files =
        targetFiles && targetFiles.length > 0
          ? targetFiles
          : e.dataTransfer!.items;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (checkFileType(file.type)) {
          data.image = file.getAsFile();
          data.imageURL = URL.createObjectURL(data.image!);
          return true;
        }
        if (file.type.endsWith("text/x-moz-url-data")) {
          file.getAsString((url: string) => {
            const urlRequest = new Request(url);
            fetch(urlRequest)
              .then(res => {
                return res.blob();
              })
              .then(response => {
                data.imageURL = URL.createObjectURL(response);
                data.image = response;
              });
          });
        }
      }
    }

    function backgroundLoaded() {
      const width = data.imageWidth!;
      const height = data.imageHeight!;
      data.minScale = Math.min(
        data.targetHeight / height,
        Math.min(data.targetWidth / width, 1)
      );

      const maxSelectorWidth = Math.min(data.targetWidth, width);
      const maxSelectorHeight = Math.min(data.targetHeight, height);

      const minSelectorWidth = 100;
      const minSelectorHeight = 100;

      const transformDraggable = true;
      editor.value!.style.setProperty("--editor-height", `${height}px`);
      editor.value!.style.setProperty("--editor-width", `${width}px`);
      background.value!.style.setProperty(
        "--background-max-width",
        `${width}px`
      );
      background.value!.style.setProperty(
        "--background-max-height",
        `${height}px`
      );

      if (!data.inputValue) {
        stage.value = markRaw(
          new UploadStage({
            container: container.value!,
            width,
            height,
            targetWidth: data.targetWidth,
            targetHeight: data.targetHeight,
            maxSelectorWidth,
            maxSelectorHeight,
            minSelectorWidth,
            minSelectorHeight,
            transformDraggable
          })
        );
      }
    }

    function selectFiles(files: any) {
      data.error = false;
      let file;
      if (files == null) return;
      if (Array.isArray(files)) {
        file = files[0];
      } else {
        file = files;
      }
      data.imageURL = URL.createObjectURL(file);
      data.image = file;
    }
    function zoom(scale: number) {
      if (data.loading) return;
      scale = Math.min(Math.max(data.minScale, scale), 1);

      nextTick(() => {
        const width = background.value!.clientWidth;
        const height = background.value!.clientHeight;

        editor.value!.style.setProperty("--editor-height", `${height}px`);
        editor.value!.style.setProperty("--editor-width", `${width}px`);

        stage.value!.width = background.value!.clientWidth;
        stage.value!.height = background.value!.clientHeight;
      });

      const width = Math.max(data.imageWidth! * scale, data.targetWidth);
      const height = data.imageHeight! * scale;

      background.value!.style.setProperty(
        "--background-max-width",
        `${width}px`
      );
      background.value!.style.setProperty(
        "--background-max-height",
        `${height}px`
      );
      data.scale = scale;
    }

    function cancelResize() {
      stageDestroy();
      data.needsScaling = false;
      data.image = null;
      data.imageURL = undefined;
      data.error = false;
      data.fileInput = undefined;
    }

    function resetSelection() {
      data.imageURL = undefined;
      data.image = null;
      data.inputValue = null;
      data.fileInput = undefined;
      data.needsScaling = false;
    }

    async function action() {
      if (data.loading) return;
      await imageFileInput.value!.$el.querySelector("input")!.click();
    }

    async function upload() {
      const uploadData = {
        responseIdentifier: props.responseIdentifier,
        name: "name" in data.image! ? data.image.name : "",
        type: data.image!.type,
        resize: undefined as { width: number; height: number } | undefined,
        crop: undefined as
          | undefined
          | {
              left: number;
              top: number;
              right: number;
              bottom: number;
            },
        arrayBuffer: null as ArrayBuffer | null
      };
      if (editDialog.value) {
        uploadData.resize = {
          width: background.value!.clientWidth,
          height: background.value!.clientHeight
        };
        uploadData.crop = stage.value!.getSelectionRect();
      } else if (data.imageWidth) {
        uploadData.resize = {
          width: data.imageWidth,
          height: data.imageHeight!
        };
      }

      const reader = new FileReader();
      reader.onload = () => {
        uploadData.arrayBuffer = reader.result as ArrayBuffer | null;
        data.loading = true;
        if (stage.value && stage.value.stage) {
          stage.value.stage.listening(false);
        }
        data.error = false;
        sendMessage("UPLOAD_INTERACTION", uploadData, [uploadData.arrayBuffer!])
          .then(result => {
            if (result) {
              stageDestroy();
              data.imageURL = result.data;
              data.inputValue = { data: result.data, name: result.name };
            }
            data.loading = false;
          })
          .catch(failure => {
            logger.warn(failure.message);
            if (stage.value && stage.value.stage) {
              stage.value.stage.listening(true);
            }
            data.errorMessage = failure.message;
            data.error = true;
            data.loading = false;
            if (!editDialog.value) data.imageURL = undefined;
          });
      };
      reader.readAsArrayBuffer(data.image!);
    }

    function stageDestroy() {
      if (stage.value) {
        stage.value.destroy();
      }

      stage.value = null;
    }

    watch(revision, revision => {
      if (!revision || !revision.form) return;
      resetSelection();
      if (revision.form[props.responseIdentifier]) {
        data.inputValue = revision.form[props.responseIdentifier];
        data.imageURL = data.inputValue ? data.inputValue.data : undefined;
      }
    });

    watch(
      () => data.inputValue,
      value => {
        commitValue(value);
      }
    );

    watch(
      () => data.imageURL,
      value => {
        if (value && !data.inputValue) {
          if (!editable) {
            upload();
          } else {
            const i = new Image();
            i.src = value;
            i.onload = () => {
              checkImageSize(i.width, i.height);
            };
          }
        }
      }
    );

    registerResponseIdentifier();
    setResponseIdentifierRequired(required);

    if (storedValue.value) {
      data.inputValue = storedValue.value;
      data.imageURL = storedValue.value.data;
    }

    addClearSelectionCb?.(resetSelection);
    interactionInitialized();

    return {
      type,
      preview,
      readOnly,
      imageFileInput,
      editor,
      background,
      container,
      editDialog,
      icons,
      action,
      upload,
      zoom,
      onPaste,
      onDrop,
      backgroundLoaded,
      selectFiles,
      cancelResize,
      ...toRefs(data)
    };
  }
});
