
import {
  defineComponent,
  provide,
  computed,
  reactive,
  useAttrs,
  ref
} from "vue";
import { storeToRefs } from "pinia";
import { VCard } from "vuetify/lib/components";

import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";

import { useStore } from "@/store/index";
import { useRoute } from "@/router";

import { assessmentItemIdKey, addClearSelectionCbKey } from "@/injectionKeys";

export default defineComponent({
  name: "assessmentItem",

  components: {
    speechSynthesisBtn
  },

  props: {
    title: String,
    identifier: { type: String, required: true }
  },

  setup(props) {
    const store = useStore();
    const root = ref<InstanceType<typeof VCard> | null>(null);
    const attrs = useAttrs();
    const clearable = attrs["data-clearable"] === "true";
    const data = reactive({
      clearCallbacks: [] as (() => void)[]
    });
    const route = useRoute();

    const { readOnly, responseRequired, assessmentItemResponseIdentifiers } =
      storeToRefs(store);

    const showComponent = computed(() => {
      if (route.query.display !== "page") return true;
      return route.query.itemId === props.identifier;
    });

    const required = computed(() => {
      if (responseRequired.value(props.identifier)) return true;
      const childrenIds =
        assessmentItemResponseIdentifiers.value[props.identifier];
      if (!childrenIds) return false;
      for (let i = 0; i < childrenIds.length; i++) {
        if (responseRequired.value(childrenIds[i])) {
          return true;
        }
      }
      return false;
    });

    const clearButton = computed(() => {
      if (!(clearable && data.clearCallbacks.length)) return false;
      const v = store.responseValue(props.identifier);
      if (Array.isArray(v)) return v.length > 0;
      else return v !== null && v !== "" && v !== undefined;
    });

    function clearSelection() {
      for (const cb of data.clearCallbacks) cb();
    }

    function addClearSelectionCb(cb: () => void) {
      data.clearCallbacks.push(cb);
    }

    provide(addClearSelectionCbKey, addClearSelectionCb);
    provide(assessmentItemIdKey, props.identifier);

    return {
      root,
      showComponent,
      readOnly,
      required,
      clearButton,
      clearSelection
    };
  }
});
