import Vue from "vue";
import VueI18n from "vue-i18n";
import { createI18n } from "vue-i18n-composable";
import { requireLocales } from "@/helpers";

Vue.use(VueI18n);

function loadLocaleMessages() {
  // it seems impossible to fix require.context for jest without babel plugins
  // not wasting time with broken chain depts, just to slow tests to a crawl, to mock one fn
  // that will have to change with vite anyway
  const locales = requireLocales();
  const messages = {} as { [index: string]: any };
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale.replace("_", "-")] = locales(key);
    }
  });
  return messages;
}

const messages = loadLocaleMessages();

export const languages = Object.keys(messages).sort();

const loadDateTimeFormats = (): VueI18n.DateTimeFormats => {
  const dateTimeFormats: VueI18n.DateTimeFormats = {};
  languages.forEach(key => {
    dateTimeFormats[key.replace("_", "-")] = {
      time: { hour: "numeric", minute: "numeric" },
      dateTime: {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        year: "numeric",
        month: "short",
        day: "numeric"
      }
    };
  });
  return dateTimeFormats;
};

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  dateTimeFormats: loadDateTimeFormats(),
  messages: messages
});

export default i18n;

export const languageText = ({
  languageCode = undefined as string | undefined,
  regionCode = undefined as string | undefined,
  locale_ = undefined as string | undefined
}) => {
  const locale = locale_ || i18n.locale;

  const language = i18n.t(`language.${languageCode}.name`, locale);
  if (regionCode) {
    const region = i18n.t(`language.regions.${regionCode}`, locale);
    return `${language} (${region})`;
  }

  return language;
};
