
import {
  defineComponent,
  reactive,
  toRefs,
  inject,
  computed,
  onMounted,
  watch,
  useAttrs
} from "vue";
import { storeToRefs } from "pinia";

import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";

import { useStore } from "@/store/index";

import {
  parentCheckboxChangedKey,
  parentResponseIdentifierKey,
  choiceTypeKey
} from "@/injectionKeys";

export default defineComponent({
  name: "simpleChoice",

  props: {
    identifier: {
      type: String
    }
  },
  components: {
    speechSynthesisBtn
  },

  setup(props, ctx) {
    const store = useStore();
    const attrs = useAttrs();

    const other = attrs["data-other"] === "true";

    const data = reactive({
      inputValue: false,

      otherInputValue: "",

      choiceLabel: ""
    });

    const parentCheckboxChanged = inject(parentCheckboxChangedKey);
    const choiceType = inject(choiceTypeKey);
    const responseIdentifier = inject(parentResponseIdentifierKey)!;

    const { revision, readOnly } = storeToRefs(store);

    const storedValue = computed(() => {
      return store.responseValue(responseIdentifier);
    });

    const storedOtherValue = computed(() => {
      return store.otherValue(responseIdentifier) || "";
    });

    const checkboxError = computed(() => {
      const required = store.responseRequired(responseIdentifier);
      if (!storedValue.value || !required) {
        return false;
      }
      return storedValue.value.length < 1;
    });

    const otherInputError = computed(() => {
      if (data.otherInputValue) return false;

      const choiceSelected = Array.isArray(storedValue.value)
        ? storedValue.value.includes(props.identifier)
        : storedValue.value === props.identifier;

      return choiceSelected;
    });

    function setInputValue() {
      if (
        choiceType?.value === "checkbox" &&
        Array.isArray(storedValue.value)
      ) {
        data.inputValue = storedValue.value.includes(props.identifier);
      }
    }

    function checkboxChanged(checked: boolean) {
      parentCheckboxChanged?.({
        identifier: props.identifier,
        checked
      });
    }

    function commitOtherValue(value: string) {
      if (value === storedOtherValue.value) return;

      store.setOtherValue({
        responseIdentifier: responseIdentifier,
        value: value
      });
    }

    watch(
      () => data.otherInputValue,
      value => {
        commitOtherValue(value);
      }
    );

    watch(revision, revision => {
      if (!responseIdentifier || !revision || !revision.form) return;
      const value = revision.form[responseIdentifier];
      if (choiceType?.value === "checkbox") {
        if (value && Array.isArray(value)) {
          data.inputValue = value.includes(props.identifier);
        } else data.inputValue = false;
      }
      if (other) {
        data.otherInputValue = revision.other[responseIdentifier];
      }
    });

    setInputValue();
    data.otherInputValue = storedOtherValue.value;

    onMounted(() => {
      // fetch the label from the default slot
      const slot = ctx.slots.default?.()[0];
      if (slot) {
        if (slot.text) data.choiceLabel = slot.text.trim();
        else if (slot.children && slot.children[1]) {
          const span = slot.children![1]!.children![0];
          data.choiceLabel = span.text ? span.text.trim() : "";
        }
      }
    });

    return {
      other,
      choiceType,
      checkboxError,
      otherInputError,
      readOnly,
      checkboxChanged,
      ...toRefs(data)
    };
  }
});
