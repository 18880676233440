
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  nextTick,
  onMounted
} from "vue";

import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";

export default defineComponent({
  name: "itemBody",

  components: {
    speechSynthesisBtn
  },

  setup() {
    // why is this being detected as Div and not as vuetify instance type?
    const root = ref<HTMLElement | null>(null);
    const speechSynthBtn = ref<InstanceType<typeof speechSynthesisBtn> | null>(
      null
    );
    const data = reactive({
      containsDescription: false,

      descriptionText: ""
    });

    onMounted(() => {
      if (!root.value) throw "invalid component root";
      const descriptionEl = root.value.querySelector(
        "p.crmx-description"
      ) as HTMLElement | null;
      data.containsDescription = !!descriptionEl;

      if (descriptionEl) {
        // fetch description paragraph text and place button at the end of the
        // description.
        nextTick(() => {
          data.descriptionText = descriptionEl.innerText.trim();
          descriptionEl.appendChild(speechSynthBtn.value!.$el);
        });
      }
    });

    return {
      root,
      speechSynthBtn,
      ...toRefs(data)
    };
  }
});
