import Vue from "vue";
import VueRouter from "vue-router";
import Assessment from "../views/Assessment.vue";
import { reactive } from "vue";
import Error from "../views/Error.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "app"
  },
  {
    path: "/qti/v1/static/:package/:identifier",
    name: "assessment",
    component: Assessment
  },
  {
    path: "/error",
    name: "error",
    component: Error
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

const currentRoute = reactive({
  ...router.currentRoute
});

router.afterEach(to => Object.assign(currentRoute, to));
export function useRoute() {
  return currentRoute;
}
export function useRouter() {
  return router;
}
