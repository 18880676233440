
import { defineComponent, onMounted, provide, ref } from "vue";

import { dragCardKey } from "@/injectionKeys";

export default defineComponent({
  name: "simpleMatchSet",

  setup() {
    const root = ref<HTMLElement | null>(null);
    const firstChild = ref<boolean>(false);

    provide(dragCardKey, firstChild);

    onMounted(() => {
      if (!root.value) throw "invalid component root";
      firstChild.value = root.value.parentElement!.firstChild === root.value;
    });

    return {
      root,
      firstChild
    };
  }
});
