
import { defineComponent, reactive, toRefs, watch, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { Stage } from "../plugins/konva";

import { useStore } from "@/store/index";

import useResponseIdentifier, {
  makeResponseIdentifierProps
} from "@/composables/useResponseIdentifier";

import { mdiSelect, mdiBrush, mdiDelete, mdiUndo, mdiRedo } from "@mdi/js";

const icons = {
  mdiSelect,
  mdiBrush,
  mdiDelete,
  mdiUndo,
  mdiRedo
};

export default defineComponent({
  name: "drawingInteraction",

  props: { ...makeResponseIdentifierProps() },

  setup(props) {
    const store = useStore();
    const slotData = ref<HTMLDivElement | null>(null);
    const root = ref<HTMLDivElement | null>(null);
    let stage: Stage | null = null;

    const { revision, readOnly } = storeToRefs(store);

    const data = reactive({
      inputValue: null as null | string,

      redoEnabled: false,
      undoEnabled: false,
      deleteEnabled: false,

      interactionMode: undefined as string | undefined,

      height: "0px",
      width: "0px"
    });

    const {
      storedValue,
      commitValue,
      registerResponseIdentifier,
      interactionInitialized
    } = useResponseIdentifier(props);

    function submissionData() {
      return stage?.toDataURL() ?? {};
    }

    function changeMode(mode: string) {
      stage!.deselect();
      data.interactionMode = mode;
    }

    function undo() {
      if (!stage) return;
      stage.undo();

      data.undoEnabled = stage.canUndo();
      data.redoEnabled = stage.canRedo();
      data.inputValue = stage.toJSON();
    }

    function redo() {
      if (!stage) return;
      stage.redo();
      data.undoEnabled = stage.canUndo();
      data.redoEnabled = stage.canRedo();
      data.inputValue = stage.toJSON();
    }

    function deleteSelected() {
      if (!stage) return;
      stage.delete();

      data.undoEnabled = stage.canUndo();
      data.redoEnabled = stage.canRedo();
      data.deleteEnabled = false;
      data.inputValue = stage.toJSON();
    }

    watch(
      () => data.inputValue,
      value => {
        commitValue(value);
      }
    );

    watch(
      () => data.interactionMode,
      v => {
        if (stage) {
          stage.mode = v;
        }
      }
    );

    watch(revision, revision => {
      if (!revision || !revision.form) return;
      data.interactionMode = "select";
      data.deleteEnabled = false;
      data.inputValue = revision.form[props.responseIdentifier];
      if (stage) {
        if (data.inputValue) {
          stage.fromJSON(data.inputValue);
        } else {
          stage.deleteAll();
        }
      }
    });

    watch(readOnly, value => {
      if (stage) {
        stage.stage?.listening(!value);

        if (value) stage.deselect();
      }
    });

    registerResponseIdentifier();
    store.setSubmissionDataFn({
      responseIdentifier: props.responseIdentifier,
      fn: submissionData
    });

    onMounted(() => {
      if (!slotData.value) throw "invalid component slot data";
      const object = slotData.value.querySelector("object")!;

      const stageWidth = parseInt(object.getAttribute("width")!, 10);
      const stageHeight = parseInt(object.getAttribute("height")!, 10);

      const imageWidth = object.dataset.imageWidth || stageWidth;
      const imageHeight = object.dataset.imageHeight || stageHeight;

      const strokeWidth = 2;
      const strokeColor = object.dataset.strokeColor || "#000000";

      data.width = `${stageWidth}px`;
      data.height = `${stageHeight}px`;

      data.inputValue = storedValue.value;

      const container = root.value!.querySelector(
        ".drawing-editor"
      ) as HTMLDivElement;

      stage = new Stage({
        container,
        width: stageWidth,
        height: stageHeight,
        loadImage: {
          src: object.getAttribute("data"),
          width: imageWidth,
          height: imageHeight
        },
        strokeColor: strokeColor,
        strokeWidth: strokeWidth,
        data: storedValue.value
      });

      stage.addEventListener("change", eventObject => {
        const obj = eventObject as Stage;
        data.undoEnabled = obj!.canUndo();
        data.redoEnabled = obj!.canRedo();
        data.deleteEnabled = obj!.canDelete();

        data.inputValue = obj.toJSON();
      });

      data.undoEnabled = stage.canUndo();
      data.redoEnabled = stage.canRedo();

      data.interactionMode = "select";
      interactionInitialized();
    });

    return {
      root,
      slotData,
      icons,
      changeMode,
      undo,
      redo,
      deleteSelected,
      readOnly,

      ...toRefs(data)
    };
  }
});
