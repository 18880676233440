import { useStore } from "@/store/index";
import { computed, ref, inject, nextTick } from "vue";
import type { Ref } from "vue";
import { propsFactory } from "@/composables/propsFactory";
import {
  assessmentItemIdKey,
  interactionInitializedKey
} from "@/injectionKeys";

export interface ResponseIdentifierProps {
  responseIdentifier: string;
}

export const makeResponseIdentifierProps = propsFactory({
  responseIdentifier: {
    type: String,
    required: true
  }
});

export default function (props: ResponseIdentifierProps) {
  const assessmentItemId = inject(assessmentItemIdKey)!;
  const interactionInitialized = inject(interactionInitializedKey)!;

  const domReady = ref(false);

  const storedValue = computed(() =>
    useStore().responseValue(props.responseIdentifier)
  );

  const responseValueModified = ref(false);

  function responseValid() {
    if (!responseValueModified.value) return true;
    return useStore().responseValid(props.responseIdentifier);
  }
  function commitValue(value: any) {
    if (value === storedValue.value) return;
    if (!responseValueModified.value) responseValueModified.value = true;

    useStore().setResponseIdentifierValue({
      responseIdentifier: props.responseIdentifier,
      value: value
    });
  }
  function setResponseIdentifierRequired(required: boolean) {
    if (!required) return;
    useStore().setResponseIdentifierRequired(props.responseIdentifier);
  }
  function registerResponseIdentifier() {
    useStore().registerResponseIdentifier(
      assessmentItemId,
      props.responseIdentifier
    );
  }

  const responseValueRules: Ref<((...args: any[]) => any)[]> = ref([
    () => responseValid()
  ]);

  nextTick(() => (domReady.value = true));

  return {
    assessmentItemId,
    domReady,
    responseValueModified,
    responseValueRules,
    storedValue,
    interactionInitialized,
    responseValid,
    commitValue,
    setResponseIdentifierRequired,
    registerResponseIdentifier
  };
}
