
import {
  defineComponent,
  onMounted,
  computed,
  reactive,
  inject,
  toRefs,
  useAttrs,
  ref,
  useSlots
} from "vue";
import { storeToRefs } from "pinia";

import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";
import choiceDragCard from "@/components/choiceDragCard.vue";

import { useStore } from "@/store/index";

import { DirectedPair } from "@/types/components";
import {
  dragCardKey,
  parentResponseIdentifierKey,
  onChoiceClickKey,
  onMatchClickRemoveKey,
  dragEventKey,
  tabularKey,
  matchRowsKey,
  matchOptionsKey,
  matchOptionErrorKey,
  onDropKey,
  onMenuClickKey,
  choiceMenuOptionsKey,
  choiceCardVisibilityKey
} from "@/injectionKeys";

import { mdiDrag, mdiClose } from "@mdi/js";
const icons = {
  mdiDrag,
  mdiClose
};

export default defineComponent({
  components: {
    speechSynthesisBtn,
    choiceDragCard
  },

  setup() {
    const store = useStore();
    const attrs = useAttrs();
    const slots = useSlots();

    const matchMax =
      attrs["match-max"] !== undefined
        ? parseInt(attrs["match-max"] as string)
        : 0;
    const identifier = attrs["identifier"] as string;

    const data = reactive({
      hover: false,
      text: ""
    });

    const tabular = inject(tabularKey);
    const dragCard = inject(dragCardKey, ref(true));
    const responseIdentifier = inject(parentResponseIdentifierKey)!;
    const rows = inject(matchRowsKey);
    const options = inject(matchOptionsKey);
    const optionError = inject(matchOptionErrorKey);
    const onDrop = inject(onDropKey);
    const onClick = inject(onChoiceClickKey, () => {}, false);
    const onMenuClick = inject(onMenuClickKey)!;
    const onClickRemove = inject(onMatchClickRemoveKey);
    const dragEvent = inject(dragEventKey)!;
    const choiceMenuOptions = inject(choiceMenuOptionsKey, ref([] as any));
    const choiceCardVisibility = inject(choiceCardVisibilityKey);

    const { readOnly } = storeToRefs(store);

    const acceptableOptions = computed(
      () => choiceMenuOptions.value[identifier]
    );

    const checkRowMax = computed(() => choiceCardVisibility?.value[identifier]);

    const checkOptionMax = computed(() => {
      return (
        matchMax === 0 ||
        matchMax >
          storedValue.value.filter(v => v.optionId === identifier).length
      );
    });

    const storedValue = computed(() => {
      return (store.responseValue(responseIdentifier) || []) as DirectedPair[];
    });

    function dropEnabled() {
      const e = dragEvent.value;
      if (!checkOptionMax.value || !e) return false;
      const id = e.dataTransfer?.getData("text");
      return !storedValue.value.find(
        v => v.rowId === id && v.optionId === identifier
      );
    }

    function onDragStart() {
      dragEvent.value = identifier;
    }

    function onDragEnd() {
      dragEvent.value = null;
    }

    function onDropEvent() {
      data.hover = false;
      onDrop?.(identifier);
    }

    function getRowMatchContent(rowId: string) {
      const row = rows!.value.find(v => v.identifier === rowId);
      return row ? (row.html ? row.html : "") : "";
    }

    onMounted(() => {
      const slot = slots.default!()[0]!;
      data.text =
        slot.text?.trim() ?? (slot.elm as HTMLElement).innerText?.trim() ?? "";
    });

    return {
      icons,
      dropEnabled,
      onDragStart,
      onDropEvent,
      onDragEnd,
      getRowMatchContent,
      identifier,
      acceptableOptions,
      checkRowMax,
      checkOptionMax,
      readOnly,
      storedValue,
      tabular,
      dragCard,
      rows,
      options,
      optionError,
      onDrop,
      onMenuClick,
      onClickRemove,
      onClick,
      ...toRefs(data)
    };
  }
});
