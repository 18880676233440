
import { defineComponent } from "vue";

export default defineComponent({
  name: "hotspotChoice",
  props: {
    identifier: { type: String, required: true },
    shape: { type: String, required: true },
    coords: { type: String, required: true }
  }
});
