
import {
  defineComponent,
  reactive,
  toRefs,
  inject,
  provide,
  toRef,
  computed,
  watch,
  ref,
  set,
  del,
  nextTick,
  onMounted,
  useAttrs
} from "vue";
import { storeToRefs } from "pinia";

import speechSynthesisBtn from "@/components/speechSynthesisBtn.vue";

import { useStore } from "@/store/index";

import useResponseIdentifier, {
  makeResponseIdentifierProps
} from "@/composables/useResponseIdentifier";

import { shuffleArray, logger } from "@/helpers";
import { MatchOptionDataType } from "@/types/components";
import { DirectedPair } from "@/types/components";

import {
  addClearSelectionCbKey,
  parentResponseIdentifierKey,
  onMatchClickRemoveKey,
  dragEventKey,
  tabularKey,
  matchRowsKey,
  matchOptionsKey,
  matchOptionErrorKey,
  onDropKey,
  onMenuClickKey,
  choiceMenuOptionsKey,
  choiceCardVisibilityKey
} from "@/injectionKeys";

export default defineComponent({
  name: "matchInteraction",
  props: { ...makeResponseIdentifierProps() },

  components: {
    speechSynthesisBtn
  },

  setup(props) {
    const store = useStore();
    const attrs = useAttrs();
    const slotData = ref<HTMLDivElement | null>(null);
    const root = ref<HTMLDivElement | null>(null);
    const rowRefs = ref<{ [id: string]: any[] }>({});

    const dragEvent = ref<any | null>(null);

    const minAssociations =
      attrs["min-associations"] !== undefined
        ? parseInt(attrs["min-associations"] as string)
        : attrs["minAssociations"] !== undefined
        ? parseInt(attrs["minAssociations"] as string)
        : 0;
    const maxAssociations =
      attrs["max-associations"] !== undefined
        ? parseInt(attrs["max-associations"] as string)
        : 1;
    const shuffle = attrs["shuffle"] === "true";

    const data = reactive({
      legacy: false,
      tabular: null as null | boolean,
      rowError: {} as { [id: string]: boolean },
      optionError: {} as { [id: string]: boolean },

      rows: [] as MatchOptionDataType[],
      options: [] as MatchOptionDataType[],
      promptText: "",
      values: [] as { rowId: string; optionId: string }[]
    });

    const {
      storedValue,
      commitValue,
      registerResponseIdentifier,
      setResponseIdentifierRequired,
      interactionInitialized
    } = useResponseIdentifier(props);

    const addClearSelectionCb = inject(addClearSelectionCbKey);

    const { revision, readOnly } = storeToRefs(store);

    const optionType = computed(() => {
      if (!data.rows) return "radio";

      // consider it to be a checkbox if it allows more than 1 matches
      if (data.legacy) {
        return data.options[0].matchMax === 1 ? "radio" : "checkbox";
      } else {
        return data.rows[0].matchMax === 1 ? "radio" : "checkbox";
      }
    });

    const menuOptions = computed(() => {
      const menus = {} as { [index: string]: MatchOptionDataType[] };
      data.rows.forEach(row => {
        menus[row.identifier] = data.options.filter(
          v =>
            !data.values.find(
              (vv: DirectedPair) =>
                vv.optionId === v.identifier && vv.rowId === row.identifier
            ) &&
            (!v.matchMax ||
              v.matchMax >
                data.values.filter(
                  (vv: DirectedPair) => vv.optionId === v.identifier
                ).length)
        );
      });
      return menus;
    });

    const choiceCardVisibility = computed(() => {
      const status = {} as { [index: string]: boolean };
      data.rows.forEach(row => {
        status[row.identifier] =
          row.matchMax === 0 ||
          row.matchMax >
            data.values.filter((v: DirectedPair) => v.rowId === row.identifier)
              .length;
      });
      return status;
    });

    function onDrop(optionId: string) {
      const rowId = dragEvent.value;
      if (!rowId) {
        logger.warn("nothing picked up?");
        return;
      }

      const option = data.options.find(v => v.identifier === optionId);
      if (
        option &&
        option.matchMax &&
        option.matchMax <=
          data.values.filter(v => v.optionId === optionId).length
      ) {
        return;
      }
      if (data.values.find(v => v.rowId === rowId && v.optionId === optionId)) {
        return;
      }
      data.values = [...data.values, { rowId, optionId }];
    }

    function onMenuClick(rowId: string, optionId: string) {
      const option = data.options.find(v => v.identifier === optionId);
      if (!option) return;
      if (
        !data.values.find(v => v.rowId === rowId && v.optionId === optionId) &&
        (!option.matchMax ||
          option.matchMax >
            data.values.filter(v => v.optionId === optionId).length)
      ) {
        data.values = [...data.values, { rowId, optionId }];
      }
    }

    function onClickRemove(rowId: string, optionId: string) {
      data.values = data.values.filter(
        v => v.rowId !== rowId || v.optionId !== optionId
      );
    }

    function checkErrors() {
      if (!storedValue.value || !data.rows) return;
      //none of the below checks were applied originally
      //options<>rows, max default to 1 would break legacy ones
      if (data.legacy) {
        if (minAssociations > 0 && data.values.length < minAssociations) {
          data.rows.forEach(v => {
            set(data.rowError, v.identifier, true);
          });
          return;
        }
        return (data.rowError = {});
      }

      if (maxAssociations > 0 && data.values.length > maxAssociations) {
        data.rows.forEach(v => {
          set(data.rowError, v.identifier, true);
        });
        return;
      }
      for (let i = 0; i < data.rows.length; i++) {
        const row = data.rows[i];
        const rowCount = data.values.filter(
          v => v.rowId === row.identifier
        ).length;
        if (
          (row.matchMax > 0 && rowCount > row.matchMax) ||
          (row.matchMin > 0 && rowCount < row.matchMin)
        ) {
          set(data.rowError, row.identifier, true);
        } else del(data.rowError, row.identifier);
      }
      for (let i = 0; i < data.options.length; i++) {
        const option = data.options[i];
        const optCount = data.values.filter(
          v => v.optionId === option.identifier
        ).length;
        if (
          (option.matchMax > 0 && optCount > option.matchMax) ||
          (option.matchMin > 0 && optCount < option.matchMin)
        ) {
          set(data.optionError, option.identifier, true);
        } else del(data.optionError, option.identifier);
      }
    }

    function clearSelection() {
      data.values = [];
      if (optionType.value === "radio") {
        data.rows.forEach(row => {
          const rowRef = rowRefs.value[row.identifier];
          for (let i = 0; i < rowRef.length; i++) rowRef[i].isActive = false;
        });
      }
    }

    function buildTable() {
      if (!root.value) throw "invalid component root";
      const qtiClass = root.value.getAttribute("class");
      if (qtiClass && qtiClass.includes("qti-match-tabular")) {
        data.tabular = true;
      } else if (qtiClass === "matchInteraction") {
        data.legacy = true;
        data.tabular = true;
      } else data.tabular = false;

      const prompt = root.value.querySelector(
        "[data-tag=prompt]"
      ) as HTMLElement;
      data.promptText = prompt?.innerText?.trim() ?? "";

      const [rowMatchSet, columnMatchSet] = slotData.value!.querySelectorAll(
        "[data-tag=simpleMatchSet]"
      );

      const rowElements = rowMatchSet.children;
      const optElements = columnMatchSet.children;

      const rows = [] as MatchOptionDataType[];
      const fixedRows = [];

      const options = [] as MatchOptionDataType[];
      const fixedOptions = [];

      for (let i = 0; i < rowElements.length; i++) {
        const element = rowElements[i] as HTMLElement;

        //it is supposed to be mandatory
        let matchMax = 0;
        if (element.hasAttribute("match-max")) {
          matchMax = parseInt(element.getAttribute("match-max")!);
        } else if (element.hasAttribute("matchMax")) {
          matchMax = parseInt(element.getAttribute("matchMax")!);
        }
        let matchMin = 0;
        if (element.hasAttribute("match-min")) {
          matchMin = parseInt(element.getAttribute("match-min")!);
        }

        const row = {
          text: element.innerText?.trim() ?? "",
          html: "",
          identifier: element.getAttribute("identifier")!,
          fixed: element.getAttribute("fixed") === "true",
          matchMax,
          matchMin,
          index: i
        };
        if (!data.tabular) {
          row.html = element.innerHTML;
        }
        if (row.fixed) {
          fixedRows.push([i, row]);
        } else {
          rows.push(row);
        }
      }

      for (let i = 0; i < optElements.length; i++) {
        const element = optElements[i] as HTMLElement;

        let matchMax = 0;
        if (element.hasAttribute("match-max")) {
          matchMax = parseInt(element.getAttribute("match-max")!);
        } else if (element.hasAttribute("matchMax")) {
          matchMax = parseInt(element.getAttribute("matchMax")!);
        }
        let matchMin = 0;
        if (element.hasAttribute("match-min")) {
          matchMin = parseInt(element.getAttribute("match-min")!);
        }

        const option = {
          text: element.innerText?.trim() ?? "",
          html: "",
          identifier: element.getAttribute("identifier")!,
          fixed: element.getAttribute("fixed") === "true",
          matchMax,
          matchMin,
          index: i
        };

        if (!data.tabular) {
          option.html = element.innerHTML;
        }

        if (option.fixed) {
          fixedOptions.push([i, option]);
        } else {
          options.push(option);
        }
      }

      if (shuffle) {
        shuffleArray(rows);
        shuffleArray(options);
      }

      fixedRows.forEach(([index, row]) => {
        rows.splice(index as number, 0, row as MatchOptionDataType);
      });

      fixedOptions.forEach(([index, option]) => {
        options.splice(index as number, 0, option as MatchOptionDataType);
      });

      if (!data.tabular && shuffle) {
        for (let i = 0; i < rows.length; i++) {
          rowMatchSet.appendChild(rowElements[rows[i].index!]);
        }
        for (let i = 0; i < options.length; i++) {
          columnMatchSet.appendChild(optElements[options[i].index!]);
        }
      }

      data.rows = rows;
      data.options = options;
    }

    function onInput(rowId: string, optionId: string) {
      if (optionType.value === "radio") {
        const rowRef = rowRefs.value[rowId];
        for (let i = 0; i < rowRef.length; i++) {
          const radio = rowRef[i];
          if (radio.value !== optionId) {
            radio.isActive = false;
          } else if (radio.value === optionId) {
            radio.isActive = true;
            data.values = [
              ...data.values.filter(v => v.rowId !== rowId),
              { rowId, optionId }
            ];
          }
        }
      } else {
        const valueIndex = data.values.findIndex(
          v => v.rowId === rowId && v.optionId === optionId
        );
        if (valueIndex >= 0) {
          data.values = data.values.filter(
            v => v.rowId !== rowId || v.optionId !== optionId
          );
        } else {
          data.values = [...data.values, { rowId, optionId }];
        }
      }
    }

    function fixRadioSelection() {
      data.rows.forEach(row => {
        const selected = data.values.find(v => v.rowId === row.identifier);
        const rowRef = rowRefs.value[row.identifier];
        for (let i = 0; i < rowRef.length; i++) {
          const radio = rowRef[i];
          if (!selected || radio.value !== selected.optionId) {
            radio.isActive = false;
          } else {
            radio.isActive = true;
          }
        }
      });
    }

    watch(
      () => data.values,
      values => {
        commitValue(values);
        checkErrors();
      }
    );

    watch(revision, revision => {
      if (!revision || !revision.form) return;
      clearSelection();
      if (revision.form[props.responseIdentifier]) {
        data.values = revision.form[props.responseIdentifier];
        if (optionType.value === "radio") {
          nextTick(fixRadioSelection);
        }
      }
    });

    provide(parentResponseIdentifierKey, props.responseIdentifier);
    provide(matchRowsKey, toRef(data, "rows"));
    provide(matchOptionsKey, toRef(data, "options"));
    provide(matchOptionErrorKey, toRef(data, "optionError"));
    provide(tabularKey, toRef(data, "tabular"));
    provide(onDropKey, onDrop);
    provide(onMenuClickKey, onMenuClick);
    provide(onMatchClickRemoveKey, onClickRemove);
    // using parent component as event bus, no reason to bother with functions anymore
    provide(dragEventKey, dragEvent);
    provide(choiceMenuOptionsKey, menuOptions);
    provide(choiceCardVisibilityKey, choiceCardVisibility);

    registerResponseIdentifier();
    setResponseIdentifierRequired(minAssociations > 0);

    if (storedValue.value != null) {
      data.values = storedValue.value;
    }

    onMounted(() => {
      buildTable();

      if (storedValue.value != null) checkErrors();

      if (data.tabular && optionType.value === "radio") {
        // must run after table is built
        nextTick(() => {
          fixRadioSelection();
        });
        addClearSelectionCb?.(clearSelection);
      }

      if (minAssociations > 0) {
        store.setCustomValidator({
          responseIdentifier: props.responseIdentifier,
          fn: () => {
            return !(
              Object.keys(data.rowError).length ||
              Object.keys(data.optionError).length
            );
          }
        });
      }

      interactionInitialized();
    });

    return {
      root,
      rowRefs,
      slotData,
      readOnly,
      optionType,
      onInput,
      ...toRefs(data)
    };
  }
});
