import Konva from "konva";

export type UploadStageOpts = {
  container: HTMLDivElement | string;
  width: number;
  height: number;
  targetWidth: number;
  targetHeight: number;
  maxSelectorWidth: number;
  maxSelectorHeight: number;
  minSelectorWidth: number;
  minSelectorHeight: number;
  transformDraggable: boolean;
};

export class UploadStage {
  TARGET_WIDTH: number;
  TARGET_HEIGHT: number;

  transformDraggable: boolean;

  maxSelectorWidth: number;
  maxSelectorHeight: number;

  minSelectorWidth: number;
  minSelectorHeight: number;

  layer: Konva.Layer;
  stage: Konva.Stage | undefined;
  image: Konva.Image | undefined;
  imageElement: HTMLImageElement | undefined;
  background: Konva.Rect | undefined;
  transformer: Konva.Transformer;
  selector: Konva.Rect;

  moving = false;
  lastPointerPosition: Konva.Vector2d | undefined;

  constructor({
    container,
    width,
    height,
    targetWidth,
    targetHeight,
    maxSelectorWidth,
    maxSelectorHeight,
    minSelectorWidth,
    minSelectorHeight,
    transformDraggable
  }: UploadStageOpts) {
    this.TARGET_WIDTH = targetWidth;
    this.TARGET_HEIGHT = targetHeight;

    this.transformDraggable = transformDraggable;

    this.maxSelectorWidth = maxSelectorWidth;
    this.maxSelectorHeight = maxSelectorHeight;

    this.minSelectorWidth = minSelectorWidth;
    this.minSelectorHeight = minSelectorHeight;

    this.stage = new Konva.Stage({
      container,
      width,
      height
    });

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    this.selector = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.maxSelectorWidth,
      height: this.maxSelectorHeight
    });
    this.layer.add(this.selector);

    this.transformer = new Konva.Transformer({
      nodes: [this.selector],
      rotateEnabled: false
    });
    this.layer.add(this.transformer);

    // first way to skip stroke resize, is just by resetting scale
    // and setting width/height instead
    this.selector.on("transform", () => {
      let width = Math.round(this.selector.width() * this.selector.scaleX());
      let height = Math.round(this.selector.height() * this.selector.scaleY());

      let x = Math.max(this.selector.x(), 0);
      let y = Math.max(this.selector.y(), 0);

      if (!this.transformDraggable) {
        if (x > 0 || y > 0) {
          this.transformer.stopTransform();
          x = 0;
          y = 0;
        }
      }

      if (width < minSelectorWidth) {
        this.transformer.stopTransform();
        width = minSelectorWidth;
      } else if (width > this.maxSelectorWidth) {
        this.transformer.stopTransform();
        width = this.maxSelectorWidth;
      }

      if (height < minSelectorHeight) {
        this.transformer.stopTransform();
        height = minSelectorHeight;
      } else if (height > this.maxSelectorHeight) {
        this.transformer.stopTransform();
        height = this.maxSelectorHeight;
      }

      this.selector.setAttrs({
        x,
        y,
        width,
        height,
        scaleX: 1,
        scaleY: 1
      });
      this.layer.batchDraw();
    });

    this.selector.on("mouseover", () => {
      if (!this.stage) return;
      this.stage.container().style.cursor = "move";
    });

    this.selector.on("mouseout", () => {
      if (!this.stage) return;
      this.stage.container().style.cursor = "default";
      this.moving = false;
      this.lastPointerPosition = undefined;
    });

    this.selector.on("mousedown touchstart", () => {
      if (!this.stage || !this.transformDraggable) return;
      this.moving = true;
      this.lastPointerPosition = this.stage.getPointerPosition()!;
    });

    this.selector.on("mouseup touchend", () => {
      this.moving = false;
    });

    this.selector.on("mousemove touchmove", () => {
      if (!this.moving || !this.stage) return;

      const pointerPosition = this.stage.getPointerPosition()!;
      const movementX = this.lastPointerPosition
        ? pointerPosition.x - this.lastPointerPosition.x
        : 0;
      const movementY = this.lastPointerPosition
        ? pointerPosition.y - this.lastPointerPosition.y
        : 0;
      this.lastPointerPosition = pointerPosition;

      const x = Math.min(
        Math.max(0, this.selector.x() + movementX),
        this.stage.width() - this.selector.width()
      );
      const y = Math.min(
        Math.max(0, this.selector.y() + movementY),
        this.stage.height() - this.selector.height()
      );

      this.selector.x(x);
      this.selector.y(y);
      this.layer.batchDraw();
    });

    this.layer.draw();
  }

  destroy() {
    this.stage?.destroy();
    this.stage = undefined;
  }

  getSelectionRect() {
    return {
      left: this.selector.x(),
      top: this.selector.y(),
      right: this.selector.x() + this.selector.width(),
      bottom: this.selector.y() + this.selector.height()
    };
  }

  get width() {
    return this.stage?.width() ?? 0;
  }

  set width(width: number) {
    if (!this.stage) return;
    this.stage.width(width);
    this.selector.x(0);
    this.selector.y(0);
    this.selector.width(Math.min(width, this.TARGET_WIDTH));
  }

  get height() {
    return this.stage?.height() ?? 0;
  }

  set height(height: number) {
    if (!this.stage) return;
    this.stage.height(height);

    this.selector.x(0);
    this.selector.y(0);
    this.selector.height(Math.min(height, this.TARGET_HEIGHT));
  }
}
