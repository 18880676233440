import Vue, { reactive, computed } from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import { defineStore } from "pinia";
import { debugMode } from "@/debug";
import { PiniaLogger } from "pinia-logger";
import { generateGetters } from "@/helpers";
import { DeepReadonly } from "ts-essentials";

Vue.use(PiniaVuePlugin);

export const pinia = createPinia();

if (debugMode) {
  pinia.use(PiniaLogger({ showDuration: true, expanded: false }));
}

const initialState = {
  templateCompiled: false,
  hidden: false,
  sectionLocked: false,

  moduleType: undefined as "qti_form" | "qti_editor" | undefined,
  editorMetadata: { word_count: 0, char_count: 0 },
  revisionDialog: false,
  revision: null as any,

  token: undefined as undefined | string,

  responseValidators: {} as { [id: string]: (value: any) => boolean },
  cleanupCallbacks: [] as Array<() => Promise<any>>,

  requiredResponseIdentifiers: [] as string[],
  responseIdentifiers: [] as string[],
  assessmentItemResponseIdentifiers: {} as { [id: string]: string[] },

  responseValues: {} as { [id: string]: any },
  otherValues: {} as { [id: string]: any },
  submissionDataFns: {} as { [id: string]: () => any },

  speechSynthesis: false,
  speechRecognitionLanguage: undefined as string | undefined,
  spellCheckLanguage: undefined as string | undefined,

  allowPrint: false,

  bookmarks: [] as string[]
};

export const useStore = defineStore("exam", () => {
  const state = reactive({ ...initialState });

  const getters = generateGetters(state);
  // TODO figure out why this won't work as a generic
  const setters = {} as {
    [id in keyof typeof state as `set${Capitalize<string & id>}`]: (
      arg: typeof state[id]
    ) => void;
  };
  for (const k in state) {
    const id = ("set" +
      k.charAt(0).toUpperCase() +
      k.slice(1)) as `set${Capitalize<string & keyof typeof state>}`;
    setters[id] = arg => {
      state[k as keyof typeof state] = arg;
    };
  }

  // the only reason I do this now is so pinia's internals recognize it as a getter
  // otherwise it will pop in onAction triggers.
  const responseValue = computed(() => (responseIdentifier: string) => {
    if (state.responseValues === undefined) return undefined;
    return state.responseValues[responseIdentifier];
  });

  const otherValue = computed(() => (responseIdentifier: string) => {
    if (state.otherValues === undefined) return undefined;
    return state.otherValues[responseIdentifier];
  });

  const responseAnswered = computed(
    () =>
      (responseIdentifier: string, parentId?: string): boolean => {
        const value = responseValue.value(responseIdentifier);
        if (!value) {
          if (responseIdentifier === parentId) return false;
          //check the sub-ids
          const item =
            state.assessmentItemResponseIdentifiers[responseIdentifier];
          if (item && item.length > 0) {
            return item.every(id =>
              responseAnswered.value(id, responseIdentifier)
            );
          }
          return false;
        } else if (Array.isArray(value)) {
          if (value.includes(responseIdentifier)) {
            // other value selected
            return !!otherValue.value(responseIdentifier);
          }
          if (state.responseValidators[responseIdentifier]) {
            return state.responseValidators[responseIdentifier](value);
          }
          return value.length > 0;
        } else if (value === responseIdentifier) {
          // other value selected
          return !!otherValue.value(responseIdentifier);
        }
        if (!!value && state.responseValidators[responseIdentifier]) {
          return state.responseValidators[responseIdentifier](value);
        } else return !!value;
      }
  );

  const responseValid = computed(
    () =>
      (responseIdentifier: string): boolean => {
        const required = responseRequired.value(responseIdentifier);
        if (!required) return true;
        return responseAnswered.value(responseIdentifier);
      }
  );

  const responseRequired = computed(() => (responseIdentifier: string) => {
    return state.requiredResponseIdentifiers.includes(responseIdentifier);
  });

  const valid = computed(() =>
    state.requiredResponseIdentifiers.every(responseIdentifier =>
      responseValid.value(responseIdentifier)
    )
  );

  const metadata = computed(
    ():
      | { word_count: number; char_count: number }
      | { questions: number; answered: number } => {
      if (state.moduleType !== "qti_editor") {
        return {
          questions: Object.keys(state.assessmentItemResponseIdentifiers)
            .length,
          answered: Object.keys(state.assessmentItemResponseIdentifiers).filter(
            v => responseAnswered.value(v)
          ).length
        };
      } else return state.editorMetadata;
    }
  );

  const submissionData = computed(() => {
    const values: any = { ...state.responseValues };
    for (const key in state.submissionDataFns) {
      values[key] = state.submissionDataFns[key]();
    }
    return values;
  });

  const readOnly = computed(() => state.revisionDialog);

  function addCleanupCallback(f: () => Promise<any>) {
    state.cleanupCallbacks.push(f);
  }

  function setSubmissionDataFn({
    responseIdentifier,
    fn
  }: {
    responseIdentifier: string;
    fn: () => any;
  }): void {
    Vue.set(state.submissionDataFns, responseIdentifier, fn);
  }

  function setResponseIdentifierValue({
    responseIdentifier,
    value
  }: {
    responseIdentifier: string;
    value: any;
  }) {
    state.responseValues = {
      ...state.responseValues,
      [responseIdentifier]: value
    };
  }

  function setOtherValue({
    responseIdentifier,
    value
  }: {
    responseIdentifier: string;
    value: any;
  }) {
    state.otherValues = {
      ...state.otherValues,
      [responseIdentifier]: value
    };
  }

  function setCustomValidator({
    responseIdentifier,
    fn
  }: {
    responseIdentifier: string;
    fn: (value?: any) => boolean;
  }) {
    Vue.set(state.responseValidators, responseIdentifier, fn);
  }

  function registerResponseIdentifier(
    assessmentItemId: string,
    responseIdentifier: string
  ) {
    state.responseIdentifiers.push(responseIdentifier);
    const item = state.assessmentItemResponseIdentifiers[assessmentItemId];
    if (item) item.push(responseIdentifier);
    else {
      Vue.set(state.assessmentItemResponseIdentifiers, assessmentItemId, [
        responseIdentifier
      ]);
    }
  }

  function setResponseIdentifierRequired(responseIdentifier: string) {
    state.requiredResponseIdentifiers = [
      ...state.requiredResponseIdentifiers.filter(
        id => id !== responseIdentifier
      ),
      responseIdentifier
    ];
  }

  function reset() {
    Object.assign(state, initialState);
  }

  return {
    // if I leave just getters I can't use devtools in full
    state: debugMode ? (state as DeepReadonly<typeof initialState>) : null,
    ...getters,
    ...setters,

    responseValue,
    otherValue,
    responseAnswered,
    responseValid,
    responseRequired,
    valid,
    metadata,
    submissionData,
    readOnly,

    addCleanupCallback,
    setSubmissionDataFn,
    setResponseIdentifierValue,
    setOtherValue,
    setCustomValidator,
    registerResponseIdentifier,
    setResponseIdentifierRequired,
    reset
  };
});
