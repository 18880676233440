
import { defineComponent } from "vue";
export default defineComponent({
  name: "crmx",

  props: {
    dataName: {
      type: String,
      required: true
    },

    dataValue: {
      type: String,
      required: true
    }
  }
});
